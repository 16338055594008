import { useState } from 'react';
import { useStyles } from './styles';
import { Slider, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledSlider = withStyles({
  root: {
    color: '#09A1F9',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#0582CA',
    border: '2px solid 623CEA',
    marginTop: 0,
    marginLeft: 0,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 8px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function SliderStyled({
  initialValue,
  style,
  disabled,
  marks,
  staticLabel,
  handleUpdate,
  required,
  field,
  labelStyle,
  bottomLabel,
  min,
  max,
  step,
}) {
  const classes = useStyles();
  const newMarks = marks ? marks : [
    { value: 6, label: '6s' },
    { value: 15, label: '15s' },
    { value: 30, label: '30s' },
    { value: 60, label: '60s' },
  ];
  const [value, setValue] = useState(initialValue ? initialValue : 20);

  const handleChange = (e, value) => {
    setValue(value);
  };

  const handleCommittedChange = (e, value) => {
    if (handleUpdate) {
      handleUpdate(value);
    }
  }

  return (
    <Grid container direction='column' style={{ width: 'auto', ...style }}>
      {staticLabel &&
        <Typography
          variant='caption'
          className={classes.staticLabel}
          style={{ color: disabled ? '#D0D0D0' : '#051923', ...labelStyle }}
        >{staticLabel}
        </Typography>
      }
      <StyledSlider
        valueLabelDisplay="auto"
        defaultValue={20}
        marks={newMarks}
        min={min ? min : 0}
        max={max ? max : 75}
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleCommittedChange}
        disabled={disabled}
        required={required}
        step={step}
      />
      {bottomLabel &&
        <Typography
          variant='caption'
          className={classes.bottomLabel}
          style={{ color: disabled ? '#D0D0D0' : '#051923' }}
        >{value + bottomLabel}
        </Typography>
      }
    </Grid>
  );
}