import React from 'react';
import { Tooltip } from '@material-ui/core';
import { useStyles } from './styles';

const TooltipStyled = ({
  title,
  style,
}) => {
  const classes = useStyles();

  return (
    <div style={style}>
      <Tooltip
        title={title}
        >
        <i className={`${"fas fa-question-circle"} ${classes.icon}`}/>
      </Tooltip>
    </div>
  );
};

export default TooltipStyled;
