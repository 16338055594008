import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowRight(props) {
    return (
      <SvgIcon {...props}>
        <path 
         d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" 
         />
      </SvgIcon>
    );
  }