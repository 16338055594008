const creativeVastTag = {
  name: "test_vide_demo_workspace_v3.mp4",  //filename
  media_assets: null,
  duration_ms: 22000,
  minimum_vast_version: "2.0",
  wrapper: {
    url: "http://demo.tremorvideo.com/proddev/vast/vast1VPAIDLinear.xml",
    secure_url: "http://demo.tremorvideo.com/proddev/vast/vast1VPAIDLinear.xml",
    ad_system: null,
    vast_version: "2.0",
    elements: [
      {
        vast_element_type_id: 1,
        type: "linear",
        trackers: null
      }
    ]
  },
  template: {
    id: 6439             //Video creatives: 6439 Audio creatives: 38745
  },
  advertiser_id: 5190305,  //advertiserID
  segments: null,
  allow_audit: "false",  //default
  is_self_audited: "false", //default
  sla: 0
}

export default creativeVastTag;
