import { useStyles } from './styles';
import {
  Typography,
} from '@material-ui/core';

export default function NativeHelpText() {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.helpText}>
        To set up a native ad in ad:personam, follow these specific instructions:
      </Typography>
      <ul>
        <li><Typography className={classes.helpText}>Upload a <b>logo</b> or icon with a size of 300 x 300 pixels and an aspect ratio of 1:1. Ensure the file is in either JPG or PNG format and does not exceed 200 KB in size.</Typography></li>
        <li><Typography className={classes.helpText}>Upload an <b>image</b> with a size of 1200 x 627 pixels and an aspect ratio of 1:1. This file should also be in JPG or PNG format and must not exceed 1 MB in size</Typography></li>
        <li><Typography className={classes.helpText}>Insert a <b>title</b> for the creative.</Typography></li>
        <li><Typography className={classes.helpText}>Provide a brief <b>description</b> of the product or service in the body textbox.</Typography></li>
        <li><Typography className={classes.helpText}>Include the brand name of the <b>sponsor</b> in the “sponsored by” field.</Typography></li>
        <li><Typography className={classes.helpText}>Add a suggested <b>call-to-action (CTA).</b></Typography></li>
      </ul>

    </div>
  );
}