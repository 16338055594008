import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
        width: '100%',
        overflow: 'hidden',
    },
    title: {
        color: '#051923',
        marginBottom: 34,
        wordBreak: "break-all",
    },
    cancelCont: {
        display: "flex",
        justifyContent: "end",
    },
    cancelText: {
        color: theme.palette.highlight.a1
    },
    cont: {
        position: 'relative',
        background: '#fff',
        padding: '32px 32px 32px 50px',
        boxShadow: '0px 8px 25px 0px #0000001A',
        [theme.breakpoints.down("sm")]: {
            padding: '32px 31px 32px 31px',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    infoCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        [theme.breakpoints.down("md")]: {
            paddingTop: 20,
        },
    },
    helpText: {
        fontFamily: 'Open Sans',
        fontWeight: 16,
        lineHeight: '21.79px',
    },
    helpTextTitle: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
    },
    dataList: {

    },
    dataItem: {
        display: "flex",
        justifyContent: "space-between"
    },
    buttons: {
        margin: '40px 0px 90px'
    },
    inputCont: {
        width: '100%',
    },
    label: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '0em',
        textAlign: 'left',
        marginTop: 24,
    },
    text: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0em',
        textAlign: 'left',
        marginTop: 24,
        wordBreak: "break-all",
    },
}));
