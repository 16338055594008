import SvgIcon from '@material-ui/core/SvgIcon';

export default function NativeAd(props) {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40">
      <rect x="2" y="7" width="36" height="25" stroke="#05CAB0" strokeWidth="2" fill="none" />
      <path d="M6 11H34" stroke="#05CAB0" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 16.6667H34" stroke="#05CAB0" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 22.3333H34" stroke="#05CAB0" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 28H34" stroke="#05CAB0" strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
  );
}