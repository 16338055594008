import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 'auto',
    padding: 30,
    paddingRight: 120,
    paddingTop: 0,
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    color: '#000',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '21.79px',
    color: '#051923',
    marginTop: 3,
    marginBottom: 6,
  },
  actionCont: {
    marginTop: 20,
  },
  closeIcon: {
    color: '#0582CA',
    fontSize: 34,
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.accent.a1
    }
  },
  dialogTitle: {
    padding: 0,
    paddingTop: 30,
  },
  dialogText: {
    padding: 0,
  }
}));
