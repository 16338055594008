import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { useGetOwnUser } from './userHooks'

export const useHubSpotHook = ({ page }) => {
  const { setPathPageView, setIdentity, setContentType } = useTrackingCode();
  const { userData } = useGetOwnUser()

  setPathPageView(page);

  if(userData) {
    setIdentity(userData.email);
  }

  setContentType("landing-page");
};