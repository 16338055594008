import { useStyles } from './styles';
import {
  Typography,
} from '@material-ui/core';

export default function AudioHelpText() {
  const classes = useStyles();

  return (
    <div>
      <ul>
        <li><Typography className={classes.helpText}>Supported formats include <b>MP3, WAC, or OGG.</b></Typography></li>
        <li><Typography className={classes.helpText}><b>Bit Rate:</b> The minimum bit rate should be 128 kbps</Typography></li>
        <li><Typography className={classes.helpText}><b>Ad Length:</b> Choose either 15 or 30 seconds for the duration of your audio ad</Typography></li>
      </ul>
    </div>
  );
}