import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  icon: {
    cursor: 'pointer',
    color: theme.palette.highlight.a1
  }
}));
