import React from 'react';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import FilenameCardSmall from '../FilenameCardSmall';

const FilenameCards = ({
  files,
  handleNewFiles
}) => {
  const classes = useStyles();

  const handleRemoveFile = (index) => {
    const newFiles = files.filter((f, i) => i!==index);
    handleNewFiles(newFiles);
  }

  return (
    <Grid container direction='column' justifyContent='center' className={classes.filesCont}>
      {files.map((file, key) => (
        <FilenameCardSmall
          key={key}
          index={key}
          name={file.name}
          handleRemoveFile={handleRemoveFile}
        />
      ))}
    </Grid> 
  );
};

export default FilenameCards;
