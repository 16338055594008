import React, { useState } from 'react';
import { useStyles } from './styles';
import { Grid, Typography, TextField } from '@material-ui/core';
import ButtonContained from 'components/elements/ButtonContained';
import ButtonOutlined from 'components/elements/ButtonOutlined';
import CreativesCreate from 'containers/CreativesCreate';



const detectAdTagType = (tagString) => {
    const lowerCaseTagString = tagString.toLowerCase();

    if (lowerCaseTagString.includes('<iframe') &&
        (lowerCaseTagString.includes(' width=') || lowerCaseTagString.includes(' height=') || lowerCaseTagString.includes(' frameborder='))) {
        return 'i-frame/JavaScript Tag';
    }
    if (lowerCaseTagString.includes('<script') && lowerCaseTagString.includes('https://ad.doubleclick.net/ddm/adj/')) {
        return 'Campaign Manager 360 JavaScript Tag';
    }
    if (lowerCaseTagString.includes('<ins') && lowerCaseTagString.includes("class='dcmads'") && lowerCaseTagString.includes('https://www.googletagservices.com/dcm/dcmads.js')) {
        return 'Campaign Manager 360 INS Tag';
    }
    if (lowerCaseTagString.includes('serving-sys.com')) {
        return 'Sizmek Tag';
    }
    if (lowerCaseTagString.includes('track.adform.net')) {
        return 'Adform Tag';
    }
    return 'Unknown Tag Type';
};


const transformLegacyJavaScriptTag = (inputTag) => {
    let transformedTag = inputTag.replace(/'/g, '"'); // Replace single quotes with double quotes

    // Replace [timestamp] with ${CACHEBUSTER}
    transformedTag = transformedTag.replace(/\[timestamp\]/ig, '${CACHEBUSTER}');

    // Replace ";click=" if it exists
    if (transformedTag.includes(';click=')) {
        transformedTag = transformedTag.replace(/;click=[^"'\s]+/, ';click=${CLICK_URL}');
    } else {
        // Append ";click=${CLICK_URL}" at the end of the URL in the script src
        // Check if the URL ends with a question mark or not
        transformedTag = transformedTag.replace(/(<script[^>]*src="[^"]+)\?"/ig, `$1;click=\${CLICK_URL}"`);
        transformedTag = transformedTag.replace(/(<script[^>]*src="[^"]+)"(?![^<]*<\/script>)/ig, `$1;click=\${CLICK_URL}"`); // If "?" is not present
    }

    return transformedTag;
};

// Transformation for 'ins' tags
const transformInsTag = (inputTag) => {
    // Consistent single quote conversion
    let transformedTag = inputTag.replace(/'/g, '"');

    // Then, handle the data-dcm-click-tracker attribute
    if (transformedTag.includes("data-dcm-click-tracker='")) {
        transformedTag = transformedTag.replace(/data-dcm-click-tracker='[^']+'/, "data-dcm-click-tracker='${CLICK_URL}'");
    }
    // Check and replace for double quotes
    else if (transformedTag.includes('data-dcm-click-tracker="')) {
        transformedTag = transformedTag.replace(/data-dcm-click-tracker="[^"]+"/, 'data-dcm-click-tracker="${CLICK_URL}"');
    }

    return transformedTag;
};

const transformIFrameJavaScriptTag = (inputTag) => {
    // Start with replacing single quotes with double quotes for consistency
    let transformedTag = inputTag.replace(/'/g, '"');

    // Replace [timestamp] with ${CACHEBUSTER}
    transformedTag = transformedTag.replace(/\[timestamp\]/ig, '${CACHEBUSTER}');

    // Handle the click macro for the iframe src attribute, considering both uppercase and lowercase tags
    if (transformedTag.match(/;click=/i)) {
        // If ";click=" already exists, replace it with the new macro, case-insensitive
        transformedTag = transformedTag.replace(/;click=[^"'\s]+/gi, ';click=${CLICK_URL}');
    } else {
        // If ";click=" does not exist, append ";click=${CLICK_URL}", handling case-insensitive SRC within IFRAME
        // Handle the case where the URL ends with a question mark
        transformedTag = transformedTag.replace(/(<iframe[^>]*src="[^"]+)\?"/gi, `$1;click=\${CLICK_URL}"`);
        // Handle the case where the URL does not end with a question mark, ensuring we don't affect other attributes or end of tag
        transformedTag = transformedTag.replace(/(<iframe[^>]*src="[^"]+)"(?![^<]*<\/iframe>)/gi, `$1?click=\${CLICK_URL}"`); // If "?" is not present
    }

    return transformedTag;
};

const transformSizmekTag = (inputTag) => {
    // Start with replacing single quotes with double quotes for consistency
    let transformedTag = inputTag.replace(/'/g, '"');

    // Replace [timestamp] with ${CACHEBUSTER} in the script tag's src attribute, using case-insensitive search
    transformedTag = transformedTag.replace(/(src="[^"]*\?[^"]*ord=)\[timestamp\]/ig, '$1${CACHEBUSTER}');

    // Append &ncu=${CLICK_URL_ENC} to the script tag's src attribute, case-insensitive
    transformedTag = transformedTag.replace(/(src="[^"]+\?[^"]*)"/ig, '$1&ncu=${CLICK_URL_ENC}"');

    // Prepend ${CLICK_URL} to the href attribute within the <noscript> tag, accommodating both http and https protocols
    transformedTag = transformedTag.replace(/(<noscript[^>]*>[\s\S]*?<a\s+href=")(https?:\/\/)/ig, `$1\${CLICK_URL}$2`);

    return transformedTag;
};


const transformAdFormTag = (inputTag) => {
    // Replace single quotes with double quotes
    let transformedTag = inputTag.replace(/'/g, '"');

    //Replace [timestamp] with ${CACHEBUSTER}
    transformedTag = inputTag.replace(/\[timestamp\]/ig, '${CACHEBUSTER}');

    // Append specific parameters to the src attribute in the <script> tag
    transformedTag = transformedTag.replace(/(<script[^>]*src="[^"]+)(?=")/ig, '$1;click=${CLICK_URL};encodedclick=${CLICK_URL_ENC};ord=${CACHEBUSTER}');

    // Prepend ${CLICK_URL} to the href attribute within the <noscript> tag, accommodating both http and https protocols
    transformedTag = transformedTag.replace(/(<noscript[^>]*>[\s\S]*?<a\s+href=")(https?:\/\/)/ig, `$1\${CLICK_URL}$2`);

    return transformedTag;
};

const transformTag = (inputTag) => {
    let transformedTag = inputTag.replace(/'/g, '"'); // Consistent single quote conversion

    // Replace [timestamp] with ${CACHEBUSTER}, using case-insensitive search for all tags
    transformedTag = transformedTag.replace(/\[timestamp\]/ig, '${CACHEBUSTER}');

    // Append specific parameters (click and encoded click) if applicable, using case-insensitive search
    transformedTag = transformedTag.replace(/(src="[^"]+\?[^"]*)"/ig, '$1&ncu=${CLICK_URL_ENC}"');

    // Prepend ${CLICK_URL} to the href attribute within <noscript>, for both http and https protocols, case-insensitive
    transformedTag = transformedTag.replace(/(<noscript[^>]*>[\s\S]*?<a\s+href=")(https?:\/\/)/ig, `$1\${CLICK_URL}$2`);

    // Handle click macro for iframe and script src attributes, considering case-insensitivity and existence of ";click="
    if (/;click=/i.test(transformedTag)) {
        transformedTag = transformedTag.replace(/;click=[^"'\s]+/gi, ';click=${CLICK_URL}');
    } else {
        // Append ";click=${CLICK_URL}" if not present, for iframe and script tags, handling URLs with or without a question mark
        transformedTag = transformedTag.replace(/(<(iframe|script)[^>]*src="[^"]+)(\?")/gi, `$1;click=\${CLICK_URL}"`);
        transformedTag = transformedTag.replace(/(<(iframe|script)[^>]*src="[^"]+)"/gi, `$1?click=\${CLICK_URL}"`);
    }

    return transformedTag;
};



const InsertMacro = () => {
    const classes = useStyles();
    const [content, setContent] = useState('');
    const [tagType, setTagType] = useState(null);
    const [transformedContent, setTransformedContent] = useState('');
    const [detectionMessage, setDetectionMessage] = useState('');

    const updateContentText = (event) => {
        setContent(event.target.value);
    };

    const handleSubmission = () => {
        let transformedTag = '';

        switch (tagType) {
            case 'Campaign Manager 360 INS Tag':
                transformedTag = transformInsTag(content);
                break;
            case 'Campaign Manager 360 JavaScript Tag':
                transformedTag = transformLegacyJavaScriptTag(content);
                break;
            case 'i-frame/JavaScript Tag':
                transformedTag = transformIFrameJavaScriptTag(content);
                break;
            case 'Sizmek Tag':
                transformedTag = transformSizmekTag(content);
                break;
            case 'Adform Tag':
                transformedTag = transformAdFormTag(content);
                break;
            default:
                transformedTag = transformTag(content); // Default behavior if tag type is unrecognized
        }
        setTransformedContent(transformedTag);
    };

    const handleDetectMacro = () => {
        // Use the 'content' state directly to get the ad tag's content
        const detectedType = detectAdTagType(content);

        // Set the detected tag type
        setTagType(detectedType);

        // Update the detection message state with the detected tag type
        const message = detectedType !== 'Unknown Tag Type' ? `Detected tag type: ${detectedType}.` : 'No specific tag type detected.';
        setDetectionMessage(message);
    };


    return (
        <CreativesCreate creativeType="InsertMacro">
            <Grid container direction='column' className={classes.root}>
                <Typography variant='h2' className={classes.title}>Ad Tag Detection and Macro Insertion Service</Typography>


                {/* Ad Tag Input TextField */}
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <TextField
                        id="filled-textarea"
                        label="Insert 3rd Party Tags"
                        multiline
                        minRows={5}
                        variant="outlined"
                        placeholder="Enter ad tag here..."
                        fullWidth  // Make TextField take the full width
                        InputProps={{
                            classes: {
                                input: classes.resize,
                            },
                        }}
                        onChange={updateContentText}
                    />
                </Grid>

                <Grid container direction='column' style={{ marginTop: 20 }}>
                    {detectionMessage && (
                        <Typography variant='subtitle1' className={classes.subtitle} style={{ marginTop: 10 }}>
                            {detectionMessage}
                        </Typography>
                    )}
                </Grid>

                <Grid container className={classes.buttons}>
                    <ButtonOutlined
                        label="Detect Tag Type"
                        onClick={handleDetectMacro}
                    />
                    <ButtonContained
                        label="Insert Macro"
                        style={{ marginLeft: 10 }}
                        onClick={handleSubmission}
                        disabled={!tagType} // Button is disabled if detection hasn't been performed                     
                    />
                </Grid>

                {/* Transformed Ad Tag TextField */}
                <Grid item xs={12} style={{ marginTop: 30, marginBottom: 20 }}>
                    <TextField
                        id="transformed-text-field"
                        label="copy the Ad Tag with inserted macro after submission "
                        multiline
                        minRows={5}
                        variant="outlined"
                        placeholder="Ad Tag with Macros"
                        value={transformedContent}
                        fullWidth  // Make TextField take the full width
                        InputProps={{
                            readOnly: true,
                        }}
                        datacy="transformed-ad-tag"
                    />
                </Grid>
            </Grid>
        </CreativesCreate>
    );
};

export default InsertMacro;


