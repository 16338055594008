import { useStyles } from './styles';
import {
  Typography,
} from '@material-ui/core';

export default function InsertMacroHelpText() {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.helpText}>
        ad:personam creative service supports tags from most of the ad servers and can automatically insert click tracking macros into some of these ad servers' tags.
      </Typography>

      <ul>
        <li><Typography className={classes.helpText}><b>1.Insert Your Ad Tag:</b> Paste your third-party ad tag into the designated input field.</Typography></li>
        <li><Typography className={classes.helpText}><b>2.Detect Tag Type:</b> Click on "Detect Tag Type" to automatically identify the type of your ad tag.</Typography></li>
        <li><Typography className={classes.helpText}><b>3.Insert Macro:</b> Once the tag type is detected, click on "Insert Macro" to apply the necessary transformations.</Typography></li>
        <li><Typography className={classes.helpText}><b>4.Use Your Transformed Tag:</b> Copy the transformed ad tag from the output area and paste it into the relevant creative upload section for your campaign.</Typography></li>
      </ul>
    </div>
  );
}