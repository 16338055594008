import React from 'react';
import {
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const ButtonStyled = withStyles({
  root: {
    '&:hover': {
      borderColor: '#09A1F9',
      color: '#09A1F9',
    },
    '&:active': {
      borderColor: '#09A1F9',
      color: '#09A1F9',
    },
    height: 40,
    padding: '8px 30px',
    borderRadius: 100,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'none',
  },
  label: {
    '&:hover': {
      color: '#09A1F9',
    },
  },
  startIcon: {
    "& > *:first-child": {
      fontSize: 16,
      position: 'relative',
      marginRight: 5,
      bottom: 1,
    }
  }
})(Button);

export default function ButtonOutlined({
  label,
  onClick,
  startIcon,
  disabled,
  endIcon,
  style,
  color,
  datacy,
}) {

  return (
    <ButtonStyled
      variant="outlined"
      color={color ? color : "primary"}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      style={style}
      data-cy={datacy}
    >
      {label}
    </ButtonStyled>
  );
}