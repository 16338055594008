import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'containers/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports-with-auth';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import ThemeProvider from '@material-ui/core/styles/ThemeProvider';
import theme from 'theme/muiTheme.js';
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider"
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from '@sentry/react';
import { BrowserTracing, Integrations } from "@sentry/tracing";
import { createBrowserHistory } from 'history'
import ErrorFallback from "./components/utils/ErrorFallback";


Amplify.configure(awsconfig);

let persistor = persistStore(store);
const history = createBrowserHistory();

const env = process.env.REACT_APP_ENV;

env && Sentry.init({
  dsn: 'https://930909af5ab548f48c33bbd4fcc673a7@o4504407733436416.ingest.sentry.io/4504407736516608',
  environment: env, // it will be 'prod' or 'dev' based on the value of REACT_APP_ENV
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [/^https:\/\/app\.adpersonam\.io/],
      tracePropagationTargets: [/^https:\/\/app\.adpersonam\.io/],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 0.6,
});


ReactDOM.render(
  <Sentry.ErrorBoundary fallback={error => <ErrorFallback error={error} />}>
    <StyledEngineProvider injectFirst>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                progressClassName="toastProgress"
                bodyClassName="toastBody"
                toastClassName="toastWrapper"
                className="toast"
              />
              <Router>
                <App />
              </Router>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </React.StrictMode>
    </StyledEngineProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
