import { useStyles } from './styles';
import {
  Typography,
} from '@material-ui/core';

export default function BannerHelpText() {
  const classes = useStyles();

  return (
    <div>
      <ul>
        <li><Typography className={classes.helpText}><b>160x600 (wide skyscraper)</b> Ideal for sidebars, this size offers high impact and visibility.</Typography></li>
        <li><Typography className={classes.helpText}><b>120x600 (skyscraper)</b> Perfect for displaying downward descending visuals in sidebars</Typography></li>
        <li><Typography className={classes.helpText}><b>300x250 (medium rectangle)</b> This ad performs well in sidebars and among organic content.</Typography></li>
        <li><Typography className={classes.helpText}><b>728x90 (leaderboard)</b> Well-recognized and often found above navigation bars and in forums</Typography></li>
        <li><Typography className={classes.helpText}><b>300x600 (half-page ad)</b> Offers a higher level of visibility due to its large size, covering nearly half of the web page</Typography></li>
        <li><Typography className={classes.helpText}><b>320x50 (mobile)</b> Specifically designed for display ads on mobile sites</Typography></li>
        <li><Typography className={classes.helpText}><b>320x480 (mobile interstitial)</b> A full-screen ad size intended for smartphones</Typography></li>
      </ul>
    </div>
  );
}