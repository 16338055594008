import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: 42
  },
  subtitle: {
    color: theme.palette.secondary.dark,
    marginTop: 42,
  },
  spinner: {
    width: 360,
    height: 10,
    borderRadius: 6,
    color: theme.palette.secondary.dark,
    marginTop: 20
  }
}));
