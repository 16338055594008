import SvgIcon from '@material-ui/core/SvgIcon';

export default function Lightbulb(props) {
  return (
    <SvgIcon {...props} width="80" height="80" viewBox="0 0 80 80">
      <path d="M39.9141 4.99999C27.3243 5.1772 17.092 15.5009 17.0007 28.0906C16.9738 31.5901 17.739 35.0503 19.239 38.2122C20.739 41.374 22.9349 44.1555 25.6624 46.3483C26.614 47.128 27.3191 48.167 27.6922 49.3394L28.7662 52.6606H51.7172L52.8153 49.2722C53.1812 48.1342 53.8696 47.1269 54.7968 46.3725C58.5716 43.3411 61.3056 39.2068 62.6172 34.5466C63.9288 29.8863 63.7525 24.9329 62.113 20.3777C60.4735 15.8225 57.4525 11.8929 53.4719 9.13752C49.4912 6.38213 44.7495 4.93846 39.9087 5.00805L39.9141 4.99999Z" fill="white" />
      <path d="M34.5898 50.7382C34.6032 51.3208 34.5898 51.6511 34.5898 51.6511" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M34.026 43.6257C34.3133 45.7361 34.4583 47.6666 34.5308 49.1057" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M31.9559 31.8254H30.3235C32.0123 34.3841 33.0326 37.9095 33.6501 41.2818" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M35.2046 31.1568L35.014 31.8254H32.5062" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M36.9982 33.0551L36.7807 33.7908L35.5268 30.0238L35.3281 30.7192" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M39.5892 32.1664L39.0925 33.7908L37.8869 30.0238L37.2399 32.2308" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M43.1093 31.7394L42.6072 30.0238L41.4016 33.7908L40.2471 30.0238L39.9088 31.1273" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M46.221 31.8254H45.4773L44.9672 30.0238L43.7106 33.7908L43.3159 32.4402" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M47.1956 39.5419C47.8293 36.7308 48.769 33.9438 50.1679 31.8254H46.9996" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M46.511 43.2767C46.6399 42.3719 46.7983 41.4348 46.9863 40.4951" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M46.0464 47.7069C46.1136 46.7618 46.2102 45.6958 46.3472 44.5574" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M45.9337 49.734C45.9337 49.487 45.9578 49.2185 45.9712 48.9285" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M45.8936 51.6511C45.8936 51.6511 45.8936 51.2107 45.9097 50.4509" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path opacity="0.2" d="M39.9141 4.99999C27.3243 5.1772 17.092 15.5009 17.0007 28.0906C16.9738 31.5901 17.739 35.0503 19.239 38.2122C20.739 41.374 22.9349 44.1555 25.6624 46.3483C26.614 47.128 27.3191 48.167 27.6922 49.3394L28.7662 52.6606H51.7172L52.8153 49.2722C53.1812 48.1342 53.8696 47.1269 54.7968 46.3725C58.5716 43.3411 61.3056 39.2068 62.6172 34.5466C63.9288 29.8863 63.7525 24.9329 62.113 20.3777C60.4735 15.8225 57.4525 11.8929 53.4719 9.13752C49.4912 6.38213 44.7495 4.93846 39.9087 5.00805L39.9141 4.99999ZM40.247 43.0458C36.727 43.0464 33.2859 42.003 30.3589 40.0476C27.4319 38.0923 25.1505 35.3128 23.8034 32.0607C22.4562 28.8087 22.1037 25.2302 22.7905 21.7778C23.4773 18.3254 25.1725 15.1542 27.6617 12.6654C30.1509 10.1765 33.3224 8.4818 36.7749 7.79553C40.2273 7.10926 43.8058 7.46228 47.0576 8.80996C50.3095 10.1576 53.0886 12.4394 55.0435 15.3667C56.9984 18.294 58.0413 21.7353 58.0402 25.2553C58.0395 29.9739 56.1646 34.499 52.8278 37.8353C49.491 41.1716 44.9656 43.0458 40.247 43.0458Z" fill="#F96109" />
      <path d="M39.9141 4.99999C27.3243 5.1772 17.092 15.5009 17.0007 28.0906C16.9738 31.5901 17.739 35.0503 19.239 38.2122C20.739 41.374 22.9349 44.1555 25.6624 46.3483C26.614 47.128 27.3191 48.167 27.6922 49.3394L28.7662 52.6606H51.7172L52.8153 49.2722C53.1812 48.1342 53.8696 47.1269 54.7968 46.3725C58.5716 43.3411 61.3056 39.2068 62.6172 34.5466C63.9288 29.8863 63.7525 24.9329 62.113 20.3777C60.4735 15.8225 57.4525 11.8929 53.4719 9.13752C49.4912 6.38213 44.7495 4.93846 39.9087 5.00805L39.9141 4.99999Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M42.9346 74.9994H37.5647C37.4315 74.9996 37.3006 74.9644 37.1855 74.8974C37.0704 74.8304 36.9752 74.7341 36.9095 74.6182L36.1041 73.2005L32.9976 71.3989H47.5312L44.4274 73.2005L43.6219 74.6182C43.553 74.7384 43.4525 74.8374 43.3314 74.9046C43.2102 74.9718 43.073 75.0046 42.9346 74.9994Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M48.9435 70.3303H31.5504C31.8583 70.9729 32.3416 71.5154 32.9443 71.8954C33.5471 72.2754 34.245 72.4775 34.9576 72.4783H45.5336C46.2465 72.4773 46.9447 72.2753 47.5479 71.8953C48.1511 71.5154 48.6348 70.9729 48.9435 70.3303Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" />
      <path opacity="0.4" d="M32.4415 65.4813L32.6322 67.1701L47.9928 65.9807L48.1888 64.2301L32.4415 65.4813Z" fill="white" />
      <path opacity="0.4" d="M38.2277 69.8256H46.2423C46.5005 69.825 46.7541 69.7565 46.9775 69.6269C47.2009 69.4973 47.3863 69.3111 47.5149 69.0872L38.2277 69.8256Z" fill="white" />
      <path opacity="0.4" d="M32.7127 67.887L32.7825 68.518C32.8274 68.8998 33.0202 69.2487 33.3195 69.4899L47.7297 68.373L47.9203 66.6788L32.7127 67.887Z" fill="white" />
      <path opacity="0.4" d="M48.5382 61.1344L48.5973 60.6027H40.1101H39.7611H31.8969L32.0982 62.4097L48.5382 61.1344Z" fill="white" />
      <path opacity="0.4" d="M32.1784 63.1266L32.3637 64.7617L48.2693 63.5293L48.4573 61.8351L32.1784 63.1266Z" fill="white" />
      <path d="M48.9436 70.3303H31.5504L29.4803 55.5899H51.0137L48.9436 70.3303Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M31 63.3333L49.6667 62.1667" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M31.5833 68L49.0833 66.8333" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M53.4141 50.9396L52.7321 58.5487L51.1372 59.7516H29.3569L27.7593 58.5487L27.08 50.9396L27.5526 49.734H52.9388L53.4141 50.9396Z" fill="#F96109" />
      <path opacity="0.2" d="M52.732 58.5487L51.1371 59.7516H29.3568L27.7592 58.5487H52.732Z" fill="black" fillOpacity="0.7" />
      <path opacity="0.4" d="M53.4141 50.9396H27.08L27.5526 49.734H52.9388L53.4141 50.9396Z" fill="white" />
      <path d="M53.4141 50.9396L52.7321 58.5487L51.1372 59.7516H29.3569L27.7593 58.5487L27.08 50.9396L27.5526 49.734H52.9388L53.4141 50.9396Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M27.08 52.1063H36.8318" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M38.3622 52.1063H40.8162" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M42.5641 52.1063H43.1091" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M53.414 52.1063H51.0137" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M52.732 57.3821H43.1091" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M41.5277 57.3821H38.3622" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M35.9054 57.3821H35.2502" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M27.7593 57.3821H30.3744" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path opacity="0.2" d="M42.5211 74.9999H38.1304C37.9781 74.9985 37.8301 74.9502 37.7078 74.8619C37.5854 74.7737 37.4948 74.6501 37.449 74.5087L36.8333 72.6667H43.8333L43.2176 74.5087C43.1717 74.6529 43.0791 74.7787 42.9535 74.8673C42.8279 74.9559 42.6762 75.0024 42.5211 74.9999Z" fill="white" />
    </SvgIcon>
  );
}