import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: 20,
    background: '#fff',
    boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.15)',
  },
  barRoot: {
    height: 50,
  },
  toolbar: {
    minHeight: 50,
  },
  selectRoot: {
    height: 50,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8,
    },
  },
  tabs: {
    marginLeft: 10, // Adjust this value to control the spacing between the select and the tabs
  },
  tab: {

  }
}));
