import React, { useState } from 'react';
import { useStyles } from './styles';
import { Grid, Typography } from '@material-ui/core';
import ButtonContained from 'components/elements/ButtonContained';
import AccordionStyled from 'components/elements/AccordionStyled';
import TextFieldStyled from 'components/elements/TextFieldStyled';
import CreativesCreate from 'containers/CreativesCreate';
import BackButtonOutlined from 'components/elements/BackButtonOutlined';
import UploadButton from 'components/elements/UploadButton';
import { useGetSelectedAdvertiser } from 'utils/hooks/advertiserHooks';
import SliderStyled from 'components/elements/SliderStyled';
import FilenameCards from 'components/elements/FilenameCards';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import uploadCreativeFile from 'utils/functions/uploadCreativeFile';
import creativeVastDefault from 'utils/models/creativeVast';
import { useEffect } from 'react';
import { trackEvent } from 'utils/hooks/analyticsG4';
import sendSlackMessage from 'utils/functions/sendSlackMessage';
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';
import * as Sentry from "@sentry/react";

const AudioCreate = ({ setCreativeType }) => {
  const classes = useStyles();
  const [activeCreative, setActiveCreative] = useState(creativeVastDefault);
  const [files, setFiles] = useState([]);
  const [mediaAssets, setMediaAssets] = useState([]);
  const { selectedAdvertiser } = useGetSelectedAdvertiser();
  const history = useHistory();
  const [videoAttribute, setVideoAttribute] = useState(creativeVastDefault.video_attribute);
  const [isUploading, setIsUploading] = useState(false);
  const [pixels, setPixels] = useState(null);

  useHubSpotHook('/creatives/create/audio');

  useEffect(() => {
    setActiveCreative([]);
  }, [])

  const handleAddAudiofile = (file) => {
    setFiles([...files, file]);
  }

  const handleUpload = async () => {
    setIsUploading(true);

    const toastId = toast('Your creatives are being uploaded...', {
      progressClassName: 'toastProgressHidden'
    });

    async function handleImages(files, type) {
      const newImageAssets = [...mediaAssets];
      for (const file of files) {
        const uploadedFile = await uploadCreativeFile(file, "audio");
        if (uploadedFile === 'File Size over 10MB') {
          toast.dismiss(toastId);
          toast.error('The file size is too large!', {
            progressClassName: 'toastProgressError'
          });
          return null;
        } else if (!uploadedFile) {
          toast.dismiss(toastId);
          toast.error('There was a problem uploading your file!', {
            progressClassName: 'toastProgressError'
          });
          Sentry.captureMessage("There was a problem uploading your file! Type: Audio");
          return null;
        }
        newImageAssets.push({
          media_asset_id: uploadedFile.data
        })
      }
      setMediaAssets(newImageAssets);
      return newImageAssets;
    }

    const media_assets = await handleImages(files, "icon_image");

    if (!media_assets) {
      setIsUploading(false);
      setFiles([]);
      return;
    }

    if (!files[0]) {
      setIsUploading(false);
      toast.dismiss(toastId);
      toast.error('Must include a file', {
        progressClassName: 'toastProgressError'
      });
      return;
    }

    const secure_url = (pixels && pixels[0]) ? pixels[0].secure_url : null;

    const newActiveCreative = {
      ...activeCreative,
      advertiser_id: selectedAdvertiser.id,
      media_assets,
      video_attribute: {
        ...videoAttribute,
        inline: {
          ...videoAttribute.inline,
          ad_title: files[0].name,
          linear: {
            ...videoAttribute.inline.linear,
            trackers: [{
              vast_event_type: "impression",
              url: activeCreative.click_url,
              secure_url
            }]
          }
        },
      },
      template: { id: 38745 },
      name: files[0].name,
      pixels,
    }

    axiosClient.post(
      `/creative-vast`,
      newActiveCreative
    ).then(createdCreative => {
      setIsUploading(false);
      toast.dismiss(toastId);
      toast.success('Your creatives have been uploaded!', {
        progressClassName: 'toastProgressSuccess'
      });
      trackEvent({
        category: 'Snippet',
        action: 'select_item',
        label: "app.adpersonam.io"
      });
      sendSlackMessage(`A new creative has been uploaded ${createdCreative.data.name} ${files[0].name} id ${createdCreative.data.id}`)
      history.push('/snippets');
    })
      .catch(err => {
        setIsUploading(false);
        const responseData = err.response.data.message;
        toast.dismiss(toastId);
        toast.error(responseData, {
          progressClassName: 'toastProgressError'
        });
        Sentry.captureException(err);
      });
  }

  const handleSlider = (value) => {
    setVideoAttribute({ ...videoAttribute, duration_ms: value * 1000 })
  }

  const updateFormText = (e) => {
    setActiveCreative({
      ...activeCreative,
      [e.target.id]: e.target.value
    })
  }

  return (
    <CreativesCreate creativeType="audio">
      <Grid container direction='column' className={classes.root}>
        <Typography variant='h2' className={classes.title}>Upload Audio</Typography>

        <Grid container alignItems='flex-end'>
          <Grid item md={5}>
            <UploadButton
              title="mp3, WAC, OGG"
              subtitle="Max. file size 10 MB"
              handleAddButton={handleAddAudiofile}
              label="Add Audio file"
              style={{ marginTop: 20 }}
              isDisabled={files.length > 0}
              datacy="button-upload-audio"
            />
          </Grid>
          <Grid item md={7}>
            <FilenameCards
              files={files}
              handleNewFiles={setFiles}
            />
          </Grid>
        </Grid>

        <SliderStyled
          staticLabel="How long is your audio in seconds?"
          style={{ marginTop: 20 }}
          marks={[{ value: 15, label: '15 seconds' },
          { value: 30, label: '30 seconds' }]}
          max={45}
          initialValue={15}
          handleUpdate={handleSlider}
        />

        <TextFieldStyled
          staticLabel="Enter the URL where users will be redirected when they click the creative"
          placeholder="This value must begin with “http://” or “https://”"
          sublabel="The landing page must match the language, brand and offer of the creative."
          style={{ marginTop: 20 }}
          handleUpdate={updateFormText}
          field="click_url"
          disabled={isUploading}
          datacy="input-audio-click-url"
        />

        <AccordionStyled
          title="Add third-party pixels (optional)"
          style={{ marginTop: 20 }}
        >
          <TextFieldStyled
            staticLabel="Add a third-party impression pixel"
            placeholder="an url-image pixel starting with https://"
            style={{ width: '100%' }}
            handleUpdate={(e) => setPixels([{ format: "url_image", "secure_url": e.target.value }])}
            field="pixels"
            disabled={isUploading}
          />
        </AccordionStyled>

        <Grid container className={classes.buttons}>
          <BackButtonOutlined
            label="Back"
          />
          <ButtonContained
            label="Upload"
            style={{ marginLeft: 10 }}
            onClick={handleUpload}
            disabled={isUploading}
            datacy="button-submit-audio"
          />
        </Grid>
      </Grid>
    </CreativesCreate>
  );
};

export default AudioCreate;
