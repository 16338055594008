const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
        resolve(base64String)
    };
    reader.onerror = error => reject(error);
});

export default toBase64;