import React, { useEffect } from 'react';

const WindowFocusHandler = ({handleOnFocus, handleOnBlur}) => {
  useEffect(() => {
    window.addEventListener('focus', handleOnFocus);
    window.addEventListener('blur', handleOnBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', handleOnFocus);
      window.removeEventListener('blur', handleOnBlur);
    };
  },[]);

  return <></>;
};

export default WindowFocusHandler;