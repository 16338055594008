import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { Typography, Grid } from "@material-ui/core";
import BackButtonOutlined from "../../elements/BackButtonOutlined";
import ButtonContained from 'components/elements/ButtonContained';
import DraggableConfirm from 'components/elements/DraggableConfirm';
import { useGetSelectedAdvertiser } from 'utils/hooks/advertiserHooks';
import { useDispatch } from 'react-redux';
import { setAdvertisers } from 'redux/slices/advertisers';
import { clearSelectedAdvertiser } from 'redux/slices/selectedAdvertiser';
import { toast } from 'react-toastify';
import axiosClient from "../../../utils/axiosClient";
import sendSlackMessage from "../../../utils/functions/sendSlackMessage";
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';
import * as Sentry from "@sentry/react";



const AdvertiserSettings = () => {
    const { selectedAdvertiser } = useGetSelectedAdvertiser();
    const [localAdvertisers, setLocalAdvertisers] = useState(null);
    const classes = useStyles();
    const dispatch = useDispatch(); // if using Redux
    const history = useHistory();
    const [toDeleteAdvertiser, setToDeleteAdvertiser] = useState(null);

    useHubSpotHook('/advertiser-settings');

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const formattedDate = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')} ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
        return formattedDate;
    };

    const deleteAdvertiser = () => {
        axiosClient.delete(`/advertiser?id=${toDeleteAdvertiser.id}`)
            .then(deletedAdvertiser => {
                // Check if localAdvertisers is not null or undefined
                if (localAdvertisers) {
                    const newAdvertisers = localAdvertisers.filter(advertiser => advertiser.id !== toDeleteAdvertiser.id);
                    dispatch(setAdvertisers(newAdvertisers));
                    setLocalAdvertisers(newAdvertisers);

                    // If the deleted advertiser is the selected advertiser, clear it
                    if (selectedAdvertiser && selectedAdvertiser.id === toDeleteAdvertiser.id) {
                        dispatch(clearSelectedAdvertiser());
                    }
                }

                // Show a success message
                toast.success('Your advertiser has been deleted', {
                    progressClassName: 'toastProgressSuccess'
                });

                sendSlackMessage(`Advertiser ID ${toDeleteAdvertiser.id} has been deleted`)

                // Redirect to dashboard
                history.push('/dashboard');
            })
            .catch(err => {
                toast.error('There was a problem deleting your advertiser', {
                    progressClassName: 'toastProgressError'
                });
                sendSlackMessage(`Failed to delete Advertiser ID ${toDeleteAdvertiser.id}`);
                Sentry.captureException(err);
            });
    };





    return (
        <Grid container direction='column' className={classes.root}>
            <Grid item xs={12} md={3} className={classes.cancelCont}>
                <Link to='/snippets'>
                    <Typography variant='subtitle1' className={classes.cancelText}>Cancel</Typography>
                </Link>
            </Grid>
            <Grid container direction='row' style={{ marginTop: 42 }}>
                <Grid item xs={12} md={9}>
                    <Grid container className={classes.cont}>
                        <Grid item>
                            <Typography variant='h2' className={classes.title}>
                                {selectedAdvertiser?.name || 'Name'}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.dataList}>
                            {selectedAdvertiser && (
                                <Grid container direction='row' className={classes.inputCont}>
                                    <Grid item xs={12} md={4} className={classes.label}>ID:</Grid>
                                    <Grid item xs={12} md={6} className={classes.text}>{selectedAdvertiser.id}</Grid>
                                    <Grid item xs={12} md={4} className={classes.label}>Created At:</Grid>
                                    <Grid item xs={12} md={6} className={classes.text}>
                                        {formatDate(selectedAdvertiser.createdAt)}
                                    </Grid>

                                    <Grid item xs={12} md={4} className={classes.label}>Timezone:</Grid>
                                    <Grid item xs={12} md={6} className={classes.text}>{selectedAdvertiser.timezone}</Grid>

                                    <Grid item xs={12} md={4} className={classes.label}>Currency:</Grid>
                                    <Grid item xs={12} md={6} className={classes.text}>{selectedAdvertiser.currency}</Grid>

                                    <Grid item xs={12} md={4} className={classes.label}>URL:</Grid>
                                    <Grid item xs={12} md={6} className={classes.text}>{selectedAdvertiser.url}</Grid>

                                    {selectedAdvertiser.legal_entity_name && (
                                        <>
                                            <Grid item xs={12} md={4} className={classes.label}>Legal Entity Name:</Grid>
                                            <Grid item xs={12} md={6} className={classes.text}>{selectedAdvertiser.legal_entity_name}</Grid>
                                        </>
                                    )}
                                </Grid>
                            )}

                        </Grid>
                        <Grid container className={classes.buttons}>
                            <BackButtonOutlined label="Back" />
                            <ButtonContained
                                label="Delete"
                                style={{ marginLeft: 10, backgroundColor: '#EA3A32', color: 'white' }}
                                onClick={() => setToDeleteAdvertiser(selectedAdvertiser)}
                            />

                        </Grid>
                        {toDeleteAdvertiser && (
                            <DraggableConfirm
                                title={`Do you really want to delete ${toDeleteAdvertiser.name}?`}
                                text="After deleting it you cannot restore it again."
                                handleCancel={() => setToDeleteAdvertiser(null)}
                                handleConfirm={deleteAdvertiser}
                                confirmLabel="Delete"
                                openState={toDeleteAdvertiser}
                                datacy="button-confirm-delete-advertiser"
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AdvertiserSettings;

