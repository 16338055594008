import React, { useState } from 'react';
import { useStyles } from './styles';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { useEffect } from 'react';
import axiosClient from 'utils/axiosClient';
import { useHistory } from 'react-router';
import Auth from '@aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { setCampaigns } from 'redux/slices/campaigns';
import { setUser } from 'redux/slices/user';
import { useGetOwnUser } from 'utils/hooks/userHooks';
import { trackEvent } from 'utils/hooks/analyticsG4';
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';

const Processing = props => {
  const classes = useStyles();
  const [status] = useState('processing');
  const history = useHistory();
  const dispatch = useDispatch();
  const { userData } = useGetOwnUser();

  useHubSpotHook('/processing');

  useEffect(() => {

    const urlParams = new URLSearchParams(history.location.search);
    const type = urlParams.get('type');
    const success = urlParams.get('success');
    const canceled = urlParams.get('canceled');

    const handleParams = async () => {

      if (type === 'subscription' && success === "true") {
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            const userObject = {
              ...userData,
              ...session.idToken.payload
            }
            dispatch(setUser(userObject));
          });
          trackEvent({
            category: 'Subscriptions',
            action: 'purchase',
            label: "app.adpersonam.io"
          });

        } catch (e) {
          console.log('Unable to refresh Token', e);
        }
        history.push({
          //pathname: '/profile',
          pathname: '/',
        })
      } else if (type === 'subscription' && (canceled === "true" || success === "false")) {
        history.push('/campaigns');

        axiosClient.get(
          `/lineitem`
        ).then(campaignsResponse => {
          if (campaignsResponse && campaignsResponse.status === 200) {
            dispatch(setCampaigns(campaignsResponse.data));
          }
          history.push('/campaigns');
        }).catch(err => {
          console.log(err.response)
        });
      } else if (type === 'payment' && (canceled === "true" || success === "false")) {
        history.push('/campaigns');
      }

    }

    handleParams().catch(err => console.log(err));
  }, [status, history])

  return (
    <Grid container direction='column' alignItems='center' className={classes.root}>

      {/* <Typography variant='h1' className={classes.title}>Transparent Pricing</Typography> */}
      <Typography variant='h6' className={classes.subtitle}>Your request is being processed...</Typography>

      <LinearProgress width={80} className={classes.spinner} />


    </Grid>
  );
};

export default Processing;
