import React, { useState } from "react";
import { useStyles } from './styles';
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";
import TextFieldStyled from "components/elements/TextFieldStyled";
import ButtonContained from "components/elements/ButtonContained";
import Haken from "icons/ui/Haken";
import { useHubSpotHook } from "utils/hooks/hubSpotHooks";

export default function ResetPassword() {
  const classes = useStyles();
  const [fields, setFields] = useState({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  // const [isConfirming, setIsConfirming] = useState(false);
  // const [isSendingCode, setIsSendingCode] = useState(false);

  useHubSpotHook('/pw-reset');

  const handleUpdateFields = (e) => {
    setFields({
      ...fields,
      [e.target.id]: e.target.value,
    })
  }

  function validateCodeForm() {
    return fields.email.length > 0;
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();

    // setIsSendingCode(true);

    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
    } catch (error) {
      // onError(error);
      // setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    // setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (error) {
      // onError(error);
      // setIsConfirming(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <form onSubmit={handleSendCodeClick}>
        <TextFieldStyled
          staticLabel="Email Address"
          style={{marginTop: 20, width: '100%'}} 
          field="email"
          type="email"
          placeholder="Email"
          handleUpdate={handleUpdateFields}
          initialValue={fields.email}
          required
          datacy="email"
        />

        <ButtonContained
          label="Send Confirmation"
          type='submit'
          style={{marginTop: 20, borderRadius: 6, width: '100%'}}
          disabled={!validateCodeForm()}
          datacy="submitCodeButton"
        />
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick}>
        <TextFieldStyled
          staticLabel="Confirmation Code"
          style={{marginTop: 20, width: '100%'}} 
          field="code"
          type="tel"
          placeholder="Code"
          handleUpdate={handleUpdateFields}
          initial=""
          required
          datacy="code"
        />
        <Typography variant='subtitle'>
          Please check your email ({fields.email}) for the confirmation code.
        </Typography>
        <hr />
        <TextFieldStyled
          staticLabel="New Password"
          style={{marginTop: 20, width: '100%'}} 
          field="password"
          type="password"
          placeholder="Email"
          handleUpdate={handleUpdateFields}
          initialValue={fields.password}
          required
          datacy="password"
        />
        <TextFieldStyled
          staticLabel="Confirm Password"
          style={{marginTop: 20, width: '100%'}} 
          field="confirmPassword"
          type="password"
          placeholder="Email"
          handleUpdate={handleUpdateFields}
          initialValue={fields.confirmPassword}
          required
          datacy="confirmPassword"
        />
        <ButtonContained 
          label="Confirm"
          type='submit'
          style={{marginTop: 20, borderRadius: 6, width: '100%'}}
          disabled={!validateResetForm()}
          datacy="submitResetButton"
        />
      </form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        <p><Haken className={classes.icon}/> Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  return (
    <Grid container direction='column' alignItems='center' className={classes.root}>

      <Grid container direction='column' className={classes.mainCont}>

        <Typography variant='h4' className={classes.title}>Password Reset</Typography>
        {!codeSent
          ? renderRequestCodeForm()
          : !confirmed
          ? renderConfirmationForm()
          : renderSuccessMessage()}

        <Typography className={classes.signIn}>
          Back to <Link to='/login'>Log in</Link>
        </Typography>
      </Grid>
    </Grid>
  );
}