import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    width: '100%',
    overflow: 'hidden',
  },
  header: {
    color: '#051923'
  },
  subtitle: {
    color: '#051923',
    fontFamily: 'Open Sans',
    fontSize: 16,
    lineHeight: '21.79px',
  },
  cancelCont: {
    position: 'relative',
    top: 20,
    textAlign: 'right',
  },
  cancelText: {
    color: theme.palette.highlight.a1
  },
  subCont: {
    position: 'relative',
    background: '#fff',
    padding: '32px 32px 32px 0px',
    boxShadow: '0px 8px 25px 0px #0000001A',
    [theme.breakpoints.down("sm")]: {
      padding: '32px 31px 32px 31px',
    },
    display: 'flex',
    flexDirection: 'row',
  },
  step: {
    color: '#0582CA',
    padding: 5,
    fontSize: 12,
    fontFamily: 'Open Sans',
    fontWeight: 700,
    marginTop: 10,
  },
  stepSelected: {
    background: theme.palette.highlight.a1,
    color: '#fff',
    padding: 5,
    fontSize: 12,
    fontFamily: 'Open Sans',
    fontWeight: 700,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginTop: 10,
  },
  leftNav: {
    borderRight: '1px solid #FAFAFA',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  infoCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  helpText: {
    fontFamily: 'Open Sans',
    fontWeight: 16,
    lineHeight: '21.79px',
  },
  helpTextTitle: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
  },
  rightPanel: {
    paddingLeft: 32,
  }
}));
