import React from 'react';
import { useStyles } from './styles';
import { Grid, Typography } from '@material-ui/core';
import ButtonOutlined from '../ButtonOutlined';
import Lightbulb from 'icons/illu/Lightbulb';
import { useHistory } from 'react-router-dom';

const InformationPanel = ({
  title,
  text,
  style,
  link = "https://adpersonam.tawk.help/", // Default link
  buttonLabel = "Learn more", // New prop for custom button label
  onLearnMore, // New prop for handling click events
  noButton,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleLearnMoreClick = () => {
    if (onLearnMore) {
      onLearnMore(history); // Pass history to the callback for internal navigation
    } else {
      window.open(link, "_blank"); // Default action for external links
    }
  };

  return (
    <Grid container direction='column' className={classes.root} style={style}>
      <Lightbulb className={classes.bulbIcon} />
      <Typography className={classes.helpTextTitle}>{title}</Typography>
      <Grid className={classes.helpText}>{text}</Grid>
      {onLearnMore && ( // Conditionally render the button based on the presence of onLearnMore
        <ButtonOutlined
          label={buttonLabel}
          style={{ marginTop: 15, width: 280, backgroundColor: '#fff' }}
          onClick={handleLearnMoreClick}
        />
      )}
    </Grid>
  );
};

export default InformationPanel;
