import { useStyles } from './styles';
import {
  Typography,
} from '@material-ui/core';

export default function AdTagHelpText() {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.helpText}>
        A URL tag is a direct reference (or link) to a specific creative asset. Using a URL tag provides a straightforward method to deliver advertisements without the complexities often seen with third-party tags.
      </Typography>
      <ul>
        <li><Typography className={classes.helpText}><b>HTML: </b>If your ad creative is an HTML content accessible through a URL. An iframe is generated to embed and display your HTML content within a webpage.</Typography></li>
        <li><Typography className={classes.helpText}><b>Javascript: </b>For ad creatives that are a piece of JavaScript code. The provided URL should point directly to the JavaScript source, which will be embedded into the webpage within a script tag.</Typography></li>
        <li><Typography className={classes.helpText}><b>Note: </b>Content within an iframe, like images, won't be directly clickable unless the HTML content itself has embedded clickable elements.
          Always ensure the URLs used are secure and adhere to industry standards for ad delivery.</Typography></li>

      </ul>

    </div>
  );
}