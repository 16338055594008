import React from 'react';
import {
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const ButtonStyled = withStyles({
  root: {
    '&:hover': {
      backgroundColor: '#09A1F9',
    },
    '&:active': {
      backgroundColor: '#09A1F9',
    },
    '&:disabled': {
      color: '#fff',
      opacity: 0.3,
      backgroundColor: '#0582CA'
    },
    height: 40,
    padding: '8px 30px',
    borderRadius: 100,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'none',
    lineHeight: 1
  },
  startIcon: {
    "& > *:first-child": {
      fontSize: 16,
      position: 'relative',
      marginRight: 5,
      bottom: 1,
    }
  }
})(Button);

export default function ButtonContained({ label, onClick, startIcon, disabled, endIcon, style, type, field, datacy, component, to }) {

  return (
    <ButtonStyled
      variant="contained"
      color="primary"
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      style={style}
      type={type}
      id={field}
      data-cy={datacy}
      component={component}
      to={to}
    >
      {label}
    </ButtonStyled>
  );
}