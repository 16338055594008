import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import {
  Typography,
} from '@material-ui/core';

const NavLink = ({ path, title, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (history.location.pathname === '/' && title === 'Dashboard') {
      setActive(true);
    } else if (history.location.pathname.includes(path) && title !== 'Dashboard') {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [history.location.pathname, path, title]);

  return (
    <div
      className={active ? classes.activeLink : classes.link}
      onClick={() => history.push(path)}
    >
      {children || <Typography>{title}</Typography>}
    </div>
  );
};


export default NavLink;


