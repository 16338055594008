import React, { useEffect } from 'react';
import './App.css';
import { Switch } from 'react-router-dom';
import { initGA } from 'utils/hooks/analyticsG4';
import { useDetectAdBlock } from "adblock-detect-react";
import errorHandler from 'utils/errorHandler';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from "components/utils/ErrorFallback";
import PrivateRoute from 'components/routing/PrivateRoute';
import PublicRoute from 'components/routing/PublicRoute';
import ScrollToTop from 'ScrollToTop';
import Processing from 'containers/Processing';
import Verification from 'containers/Verification/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import ComponentsBoard from 'containers/ComponentsBoard/Loadable';
import Profile from 'containers/Profile/Loadable';
import SignUp from 'containers/SignUp/Loadable';
import Login from 'containers/Login/Loadable';
import AdvertiserCreate from 'containers/AdvertiserCreate/Loadable';
import Snippets from 'containers/Snippets/Loadable';
import Campaigns from 'containers/Campaigns/Loadable';
import Reports from 'containers/Reports/Loadable';
import CreativesCreate from 'containers/CreativesCreate/Loadable';
import PixelsCreate from 'containers/PixelsCreate/Loadable';
import PixelsGeneralCreate from 'containers/PixelsGeneralCreate/Loadable';
import RemarketingCreate from 'containers/RemarketingCreate/Loadable';
import DomainsCreate from 'containers/DomainsCreate/Loadable';
import CampaignsCreate from 'containers/CampaignsCreate/Loadable';
import CampaignDetails from 'containers/CampaignDetails/Loadable';
import DataMarketplace from "containers/DataMarketplace/Loadable";
import Supply from "containers/Supply/Loadable";
import Subscribe from 'containers/Subscribe/Loadable';
import PricingTable from 'containers/PricingTable';
import Assistant from "containers/Assistant/Loadable";
import ResetPassword from 'components/utils/ResetPassword';
import AdvertiserSettings from "../../components/snippets/AdvertiserSettings";
import BannerCreate from 'components/creatives/BannerCreate';
import NativeCreate from 'components/creatives/NativeCreate';
import VideoCreate from 'components/creatives/VideoCreate';
import AudioCreate from 'components/creatives/AudioCreate';
import HtmlCreate from "../../components/creatives/HtmlCreate";
import JsTagCreate from "../../components/creatives/JsTagCreate";
import InsertMacro from "../../components/creatives/InsertMacro";
import VideoTagCreate from "../../components/creatives/VideoTagCreate";
import UrlTagCreate from "../../components/creatives/UrlTagCreate";
import DomainsQuery from 'components/domains/DomainsQuery';
import * as Sentry from '@sentry/react';


const App = props => {
  const adBlockDetected = useDetectAdBlock();


  useEffect(() => {
    if (adBlockDetected) {
      // Show an alert if an ad blocker is detected
      alert("AD BLOCKER DETECTED: We've noticed that you are using an ad blocker. Please be aware that our app relies on certain scripts and functionalities that ad blockers may disrupt. To ensure the best experience and full functionality, we recommend disabling your ad blocker while using our app. Thank you for your understanding.");
    }
  }, [adBlockDetected]);

  useEffect(() => {
    // Initialize Google Analytics only in production environment
    if (process.env.REACT_APP_ENV === 'prod') {
      initGA(); // This will initialize GA4 tracking
    }
  }, []);

  /*  useEffect(() => {
     // Initialize Google Analytics for testing
     initGA(); // This will initialize GA4 tracking
   }, []); */


  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        console.log('Resetting');
      }}
      onError={(err, info) => {
        errorHandler(err, info);
      }}
    >
      <div className="App">
        <ScrollToTop />
        <Switch>

          <PublicRoute exact path="/login" component={Login} displayName='Login' />
          <PublicRoute exact path="/sign-up" component={SignUp} displayName='Sign Up' />
          <PublicRoute exact path="/pw-reset" component={ResetPassword} displayName='Reset Password' />
          <PublicRoute exact path="/verification/:codeParameter/:userName/:clientId/:email" component={Verification} displayName='Verification' />
          <PrivateRoute exact path="/dev/components" component={ComponentsBoard} displayName='Components Board' />
          <PrivateRoute exact path="/" component={Dashboard} displayName='Dashboard' />
          <PrivateRoute exact path="/dashboard" component={Dashboard} displayName='Dashboard' />
          <PrivateRoute exact path="/profile" component={Profile} displayName='Profile' />
          <PrivateRoute exact path="/snippets" component={Snippets} displayName='Snippets' />
          <PrivateRoute exact path="/campaigns" component={Campaigns} displayName='Campaigns' />
          <PrivateRoute exact path="/reports" component={Reports} displayName='Reports' />
          <PrivateRoute exact path="/subscribe" component={Subscribe} displayName='Subscribe' />
          <PrivateRoute exact path="/sellers" component={Supply} displayName='Sellers' />
          <PrivateRoute exact path="/data-marketplace" component={DataMarketplace} displayName='Data Marketplace' />
          <PrivateRoute exact path="/advertisers/create" component={AdvertiserCreate} displayName='Create Advertiser' />
          <PrivateRoute exact path="/creatives/create" component={CreativesCreate} displayName='Create Creatives' />
          <PrivateRoute exact path="/pixels/create" component={PixelsCreate} displayName='Create Pixels' />
          <PrivateRoute exact path="/pixels-general/create" component={PixelsGeneralCreate} displayName='Create Standard Pixels' />
          <PrivateRoute exact path="/remarketing/create" component={RemarketingCreate} displayName='Create Marketing Audience' />
          <PrivateRoute exact path="/domains/create" component={DomainsCreate} displayName='Create Domains' />
          <PrivateRoute exact path="/campaigns/edit/:campaignType/:step" component={CampaignsCreate} displayName='Create Campaign' />
          <PrivateRoute exact path="/campaigns/details/:advertiserId/:insertionOrderId/:profileId/:lineItemId" component={CampaignDetails} displayName='Campaign Details' />
          <PrivateRoute exact path="/pricing" component={PricingTable} displayName='Pricing' />
          <PrivateRoute exact path="/processing" component={Processing} displayName='Processing' />
          <PrivateRoute exact path="/advertiser-settings" component={AdvertiserSettings} displayName='Advertiser Settings' />
          <PrivateRoute exact path="/creatives/create/banner" component={BannerCreate} displayName='Create Banner Creatives' />
          <PrivateRoute exact path="/creatives/create/native" component={NativeCreate} displayName='Create Native Creatives' />
          <PrivateRoute exact path="/creatives/create/video" component={VideoCreate} displayName='Create Video Creatives' />
          <PrivateRoute exact path="/creatives/create/audio" component={AudioCreate} displayName='Create Audio Creatives' />
          <PrivateRoute exact path="/creatives/create/html5" component={HtmlCreate} displayName='Create HTML5 Creatives' />
          <PrivateRoute exact path="/creatives/create/ad-tag" component={JsTagCreate} displayName='Upload 3rd Party Tag' />
          <PrivateRoute exact path="/creatives/create/macro-check" component={InsertMacro} displayName='Insert Macros' />
          <PrivateRoute exact path="/creatives/create/vast-tag" component={VideoTagCreate} displayName='Upload 3rd Party Vast Tag' />
          <PrivateRoute exact path="/creatives/create/url-tag" component={UrlTagCreate} displayName='Upload Url Tag' />
          <PrivateRoute exact path="/domains-query" component={DomainsQuery} displayName='Query Domains' />
          <PrivateRoute exact path="/assistant" component={Assistant} displayName='Planner Assistant' />
        </Switch>
      </div>
    </ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);
