import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  deleteIcon: {
    cursor: 'pointer',
    color: '#0582CA',
    fontSize: 15,
    position: 'relative',
    top: 2,
  },
  fileName: {
    color: '#050A23',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
}));
