const domainsQueryCountries = [
    { label: 'Italy', value: 'Italy', disabled: false },
    { label: 'Germany', value: 'Germany', disabled: false },
    { label: 'United Kingdom', value: 'United Kingdom', disabled: false },
    { label: 'France', value: 'France', disabled: false },
    { label: 'Poland', value: 'Poland', disabled: false },
    { label: 'Netherlands', value: 'Netherlands', disabled: false },
    { label: 'Switzerland', value: 'Switzerland', disabled: false },
    { label: 'Ireland', value: 'Ireland', disabled: false },
    { label: 'Finland', value: 'Finland', disabled: false },
    { label: 'United States', value: 'United States', disabled: false },
    { label: 'Canada', value: 'Canada', disabled: false },
    { label: 'Australia', value: 'Australia', disabled: false },
    { label: 'New Zealand', value: 'New Zealand', disabled: false },
    { label: 'Japan', value: 'Japan', disabled: false },
    { label: 'Brazil', value: 'Brazil', disabled: false },
    { label: 'Argentina', value: 'Argentina', disabled: false },
    { label: 'Chile', value: 'Chile', disabled: false }

];

export default domainsQueryCountries;
















