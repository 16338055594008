import { createTheme } from '@material-ui/core/styles';

let theme = createTheme({
  typography: {
    fontFamily: 'Poppins, OpenSans',
    h1: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: 32,
      lineHeight: '48px',
    },
    h1mobile: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: 26,
      lineHeight: '39px',
    },
    h2: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: 24,
      lineHeight: '36px',
    },
    h2mobile: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: 22,
      lineHeight: '33px',
    },
    h3: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: 18,
      lineHeight: '27px',
    },
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#0582CA',
      dark: '#003554'
    },
    secondary: {
      main: '#09A1F9',
      dark: '#006494'
    },
    highlight: {
      a1: '#0582CA',
      a2: '#09A1F9',
      a1hover: '#051FCA',
    },
    black: '#051923',
    grey: {
      dark: '#050A23',
      mid: '#A7A7A7',
      midLight: '#D0D0D0',
      light: '#FAFAFA',
    },
    white: '#FFFFFF',
    accent: {
      a1: '#05CAB0',
      a2: '#F96109',
    },
    state: {
      success: '#05cab0'
    },
    contrastThreshold: 2,
    tonalOffset: 0.2,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        height: 40,
        borderRadius: 100,
        padding: '8px 30px',
      },
      label: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 16,
        textTransform: 'none',
      },
      outlined: {
        padding: '8px 30px'
      },
      startIcon: {
        marginRight: 2,
        position: 'relative',
        top: 1,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#05CAB0',
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: '#05CAB0',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: 0
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: 18,
        position: 'relative',
        bottom: 12,
      }
    },
    MuiOutlinedInput: {
      root: {
        border: '1px solid #D0D0D0',
        borderRadius: 5,
        '&:focus': {
          borderRadius: 5,
          border: '2px solid',
          borderColor: '#05CAB0',
        },
      },
      input: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
      },
      adornedEnd: {
        paddingRight: 0
      }
    },
  },


});

export default theme;