import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setUser } from 'redux/slices/user';
import { selectProfile, setProfile } from 'redux/slices/profile';
import { useEffect, useState } from 'react';
import axiosClient from 'utils/axiosClient';
import subscriptions from 'utils/consts/subscriptions';
import * as Sentry from "@sentry/react";

export const useGetOwnUser = () => {
  const { value, isRetrieved } = useSelector(selectUser);

  localStorage.setItem('user-data', JSON.stringify(value));
  return { userData: value, isRetrieved };
};

export const useGetProfile = () => {
  const { value, isRetrieved } = useSelector(selectProfile);

  return { profile: value, isRetrieved };
};

export const useGetInitialProfile = () => {
  const [initialProfile, setInitialProfile] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getProfile() {
      const profileResponse = await axiosClient.get(
        `/profile`
      );
      // console.log('Initial Profile Response:', profileResponse);
      if(profileResponse.status===200) {
        setInitialProfile(profileResponse.data);
        dispatch(setProfile(profileResponse.data));
      }
    }
    getProfile();
  },[dispatch])

  return { initialProfile };
};

export const useGetUserSubscription = () => {
  const [subscription, setSubscription] = useState();
  const dispatch = useDispatch();
  const {userData} = useGetOwnUser();

  useEffect(() => {
    async function getSubscription(userData) {
      const subResponse = await axiosClient.get(
        `/user/subscription`
      ).catch(err => {
        console.log(err)
        Sentry.captureException(err);
      });
      // console.log('Initial Profile Response:', profileResponse);
      if(subResponse.status===200) {
        const userPlusSub = {...userData, ...subResponse.data};
        setSubscription(subResponse.data);
        dispatch(setUser(userPlusSub));
      }
    }
    if(userData) {
      getSubscription(userData);
    }
  },[dispatch, userData])

  return { subscription };
};

export const useGetUserSubLevel = () => {
  const {userData} = useGetOwnUser();
  const [hasStarter, setHasStarter] = useState(false);
  const [hasPro, setHasPro] = useState(false);

  useEffect(() => {
    if(userData && userData["custom:subscription"]) {
      if(userData["custom:subscription"].toUpperCase()===subscriptions['bottom']) {
        setHasStarter(true);
      } else {
        setHasStarter(false);
      }
      if(userData["custom:subscription"].toUpperCase()===subscriptions['top']) {
        setHasPro(true);
      } else {
        setHasPro(false);
      }
    }
  },[userData])

  return { hasStarter, hasPro };
};