import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdvertisers, setAdvertisers } from 'redux/slices/advertisers';
import { selectSelectedAdvertiser } from 'redux/slices/selectedAdvertiser';
import axiosClient from 'utils/axiosClient';
import * as Sentry from "@sentry/react";

export const useGetAdvertisers = () => {
  const { value, isRetrieved } = useSelector(selectAdvertisers);

  return { advertisers: value, isRetrieved };
};

export const useGetInitialAdvertisers = () => {
  const [initialAdvertisers, setInitialAdvertisers] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getAdvertiser() {
      const advertisersResponse = await axiosClient.get(
        `/advertiser`
      );
      if (advertisersResponse.status === 200) {
        setInitialAdvertisers(advertisersResponse.data);
        dispatch(setAdvertisers(advertisersResponse.data));
      }
    }
    getAdvertiser().catch(err => {
      console.log(err)
      Sentry.captureException(err);
    });
  }, [dispatch])

  return { initialAdvertisers };
};

export const useGetSelectedAdvertiser = () => {
  const { value, isRetrieved } = useSelector(selectSelectedAdvertiser);

  return { selectedAdvertiser: value, isRetrieved };
};

export const useGetAdvertiserReport = () => {
  const [reportUrl, setReportUrl] = useState();
  const { selectedAdvertiser } = useGetSelectedAdvertiser(false);

  useEffect(() => {
    if (selectedAdvertiser && selectedAdvertiser.id) {
      async function getAdvertiserReport() {
        try {
          const reportResponse = await axiosClient.get(
            `/dashboard?advertiser_id=` + selectedAdvertiser.id
          );
          if (reportResponse.status === 200 && reportResponse.data) {
            setReportUrl(reportResponse.data.dashboard);
          }
        } catch (err) {
          console.log('get advertiser report err:', err);
          console.log(err.response);
          Sentry.captureException(err);
        }
      }
      getAdvertiserReport();
    }
  }, [selectedAdvertiser]);

  return { reportUrl };
};


