import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    padding: '10px 10px',
    borderRadius: 4,
    border: '1px solid #A7A7A7',
    width: 'auto',
    paddingTop: 20,
    paddingBottom: 20,
  },
  title: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '21.79px',
    color: '#051923',
  },
  description: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '19.07px',
    color: '#050A23',
    marginTop: 3,
    marginBottom: 6,
  },
  icon: {
    color: '#0582CA',
    paddingLeft: 5,
    paddingRight: 15,
  },
  arrow: {
    color: '#0582CA',
    paddingLeft: 5,
    fontSize: 30,
  },
  actionText: {
    textAlign: 'center',
  },
  xIcon: {
    color: '#0582CA',
    fontSize: 34,
  }
}));
