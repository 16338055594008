import React, { useState, useEffect } from 'react';
import { useGetOwnUser } from 'utils/hooks/userHooks';
import { Typography, Grid } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Help from 'icons/ui/Help';
import User from 'icons/ui/User';
import Targeting from 'icons/ui/Targeting';
import Supply from 'icons/ui/Supply';
import Homepage from 'icons/ui/Homepage';
import BookADemo from 'icons/ui/BookADemo';
import SparkAi from 'icons/ui/SparkAi';
import PricingTable from "icons/ui/PricingTable";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

const LeftSidebarAssistant = () => {
    const classes = useStyles();
    const { userData } = useGetOwnUser();
    const [hasPlan, setHasPlan] = useState(false);

    useEffect(() => {
        if (userData && userData["custom:subscription"]) {
            setHasPlan(true);
        } else {
            setHasPlan(false);
        }
    }, [userData]);

    return (

        <Grid container direction='column' className={classes.root}>

            <Link to='/'>
                <IconButton className={classes.icon}>
                    <Homepage />
                    <Typography variant="body1" style={{ marginLeft: '20px' }}>Home</Typography>
                </IconButton>
            </Link>

            <Link to='/profile'>
                <IconButton className={classes.icon}>
                    <User />
                    <Typography variant="body1" style={{ marginLeft: '20px' }}>Profile</Typography>
                </IconButton>
            </Link>

            {/* Display the "Subscribe" button only if the user does NOT have a plan */}
            {!hasPlan && (
                <Grid item>
                    <Link to='/subscribe'>
                        <IconButton className={classes.icon}>
                            <PricingTable />
                            <Typography variant="body1" style={{ marginLeft: '20px' }}>Subscribe</Typography>
                        </IconButton>
                    </Link>
                </Grid>

            )}

            <Link to='/assistant'>
                <IconButton className={classes.icon}>
                    <SparkAi />
                    <Typography variant="body1" style={{ marginLeft: '20px' }}>AI Assistant</Typography>
                </IconButton>
            </Link>

            <Link to='/domains-query'>
                <IconButton className={classes.icon}>
                    <SparkAi />
                    <Typography variant="body1" style={{ marginLeft: '20px' }}>Domains Query</Typography>
                </IconButton>
            </Link>

            <Link to='/data-marketplace'>
                <IconButton className={classes.icon}>
                    <Targeting />
                    <Typography variant="body1" style={{ marginLeft: '20px' }}>Data Marketplace</Typography>
                </IconButton>
            </Link>

            <Link to='/sellers'>
                <IconButton className={classes.icon}>
                    <Supply />
                    <Typography variant="body1" style={{ marginLeft: '20px' }}>Inventory</Typography>
                </IconButton>
            </Link>


            <Link to={{ pathname: 'https://www.adpersonam.io/demand-side-platform-demo/' }} target='_blank'>
                <IconButton className={classes.icon}>
                    <BookADemo />
                    <Typography variant="body1" style={{ marginLeft: '20px' }}>Book a Demo</Typography>
                </IconButton>
            </Link>


            <Link to={{ pathname: 'https://adpersonam.tawk.help/' }} target='_blank'>
                <IconButton className={classes.icon}>
                    <Help />
                    <Typography variant="body1" style={{ marginLeft: '20px' }}>Knowledge Base</Typography>
                </IconButton>
            </Link>

        </Grid>
    );
};

export default LeftSidebarAssistant;
