import axiosClient from 'utils/axiosClient';
import * as Sentry from "@sentry/react";

const uploadCreativeFile = async (file, type) => {

  const formData = new FormData();
  if (file.size > 10000000) {
    return 'File Size over 10MB';
  }
  formData.append('content', file);
  formData.append('file_name', file.name);
  formData.append('type', type);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  const uploadedFile = await axiosClient.post(
    `/creative-upload-file`,
    formData,
    config
  ).catch(err => {
    Sentry.captureException(err);
    console.log(err.response);
    return;
  });

  return uploadedFile;

}

export default uploadCreativeFile;

