const creativeDefault = {
    advertiser_id: 0,
    template: {
        id: 4
    },
    file_name: "string",
    content: "string",
    width: 0,
    height: 0,
    audit_status: "no_audit",
    allow_audit: "false"
}
  
export default creativeDefault;