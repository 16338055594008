const domainsQueryCat = [
    { label: 'Pets & Animals', value: 'Pets & Animals', disabled: false },
    { label: 'Autos & Vehicles', value: 'Autos & Vehicles', disabled: false },
    { label: 'Beauty & Personal Care', value: 'Beauty & Personal Care', disabled: false },
    { label: 'Business & Industry', value: 'Business & Industry', disabled: false },
    { label: 'Computers & Electronics', value: 'Computers & Electronics', disabled: false },
    { label: 'Arts & Entertainment', value: 'Arts & Entertainment', disabled: false },
    { label: 'Finance', value: 'Finance', disabled: false },
    { label: 'Food & Drink', value: 'Food & Drink', disabled: false },
    { label: 'Games', value: 'Games', disabled: false },
    { label: 'Health', value: 'Health', disabled: false },
    { label: 'Home & Garden', value: 'Home & Garden', disabled: false },
    { label: 'Internet & Telecom', value: 'Internet & Telecom', disabled: false },
    { label: 'Lifestyles', value: 'Lifestyles', disabled: false },
    { label: 'News', value: 'News', disabled: false },
    { label: 'Online Communities', value: 'Online Communities', disabled: false },
    { label: 'Real Estate', value: 'Real Estate', disabled: false },
    { label: 'Recreation', value: 'Recreation', disabled: false },
    { label: 'Reference & Language', value: 'Reference & Language', disabled: false },
    { label: 'Science', value: 'Science', disabled: false },
    { label: 'Shopping', value: 'Shopping', disabled: false },
    { label: 'Sports', value: 'Sports', disabled: false },
    { label: 'Travel', value: 'Travel', disabled: false },
    { label: 'Books & Literature', value: 'Books & Literature', disabled: false },
    { label: 'Education', value: 'Education', disabled: false },
    { label: 'Law & Government', value: 'Law & Government', disabled: false },
    { label: 'World Localities', value: 'World Localities', disabled: false }
];

export default domainsQueryCat;
