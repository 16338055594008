// import axiosClient from 'utils/axiosClient';
import axios from "axios";

const sendSlackMessage = async (message) => {
  
  const urls = {
    dev: `https://hooks.slack.com/services/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`,
    prod: `https://hooks.slack.com/services/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`
  }
  
  const url = process.env.REACT_APP_ENV ? urls[process.env.REACT_APP_ENV] : urls['dev'];

  axios({
    method: "post",
    url,
    headers: {
      "Content-type": 'application/x-www-form-urlencoded'
    },
    data: { text: `${message}` },
  }).catch(err => console.log(err))
};

export default sendSlackMessage;