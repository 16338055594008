import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import CardSmall from 'components/elements/CardSmall';
import NativeAd from 'icons/creativeType/NativeAd';
import VideoAd from 'icons/creativeType/VideoAd';
import AudioAd from 'icons/creativeType/AudioAd';
import ButtonContained from 'components/elements/ButtonContained';
import { useGetOwnUser, useGetUserSubLevel } from 'utils/hooks/userHooks';

const CreativesSelectType = ({ setCreativeType }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState('banner')
  const history = useHistory();
  const { userData } = useGetOwnUser();
  const [hasPlan, setHasPlan] = useState(false);
  const { hasStarter, hasPro } = useGetUserSubLevel();

  useEffect(() => {
    if (userData && userData["custom:subscription"]) {
      setHasPlan(true);
    } else {
      setHasPlan(false);
    }
  }, [userData])


  const isUnlicensedOrDisabled = !hasPlan || hasStarter;

  const handleSelected = (field) => {
    setSelected(field);
  }

  const submitStep = () => {
    history.push('/creatives/create/' + selected);
  }

  return (
    <Grid container direction='column' className={classes.root}>

      <Typography variant='h2' className={classes.title}>
        Creative Ad Formats
      </Typography>

      {/* Display Group */}
      <Typography variant='h4' className={classes.subtitle}>
        Display Ad Formats
      </Typography>

      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <CardSmall
            title='Banner'
            actionText="updload JPEG, JPG, PNG, GIF, or SWF images."
            icon={<NativeAd className={classes.icon} />}
            isSelected={selected === 'banner'}
            handleSelected={handleSelected}
            field='banner'
            noFalse
            datacy="card-creative-type"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <CardSmall
            title='Url Tags'
            actionText="direct link (URL) to HTML or Javascript creative assets."
            icon={<NativeAd className={classes.icon} />}
            isSelected={selected === 'url-tag'}
            handleSelected={handleSelected}
            field='url-tag'
            noFalse
            datacy="card-creative-type"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <CardSmall
            title='HTML5'
            actionText=".zip files including HTML, CSS, JS, and images."
            icon={<NativeAd className={classes.icon} />}
            isSelected={selected === 'html5'}
            handleSelected={handleSelected}
            field='html5'
            noFalse
            unlicensed={isUnlicensedOrDisabled}
            disabled={isUnlicensedOrDisabled}
            datacy="card-creative-type"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <CardSmall
            title='3rd Party Tags'
            actionText="HTML or Javascript third party ad-server tags."
            icon={<NativeAd className={classes.icon} />}
            isSelected={selected === 'ad-tag'}
            handleSelected={handleSelected}
            field='ad-tag'
            noFalse
            unlicensed={isUnlicensedOrDisabled}
            disabled={isUnlicensedOrDisabled}
            datacy="card-creative-type"
          />
        </Grid>
      </Grid>

      <Typography variant='h4' className={classes.subtitle}>
        Multimedia Ad Formats
      </Typography>
      <Grid container spacing={2}>

        <Grid item md={3} xs={12}>
          <CardSmall
            title='Video'
            actionText="MOV, MP4, OGG and QuickTime (QT)"
            icon={<VideoAd className={classes.icon} />}
            isSelected={selected === 'video'}
            handleSelected={handleSelected}
            field='video'
            noFalse
            unlicensed={isUnlicensedOrDisabled}
            disabled={isUnlicensedOrDisabled}
            datacy="card-creative-type"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <CardSmall
            title='VAST & VPAID'
            actionText="Video XML-based video ad serving"
            icon={<VideoAd className={classes.icon} />}
            isSelected={selected === 'vast-tag'}
            handleSelected={handleSelected}
            field='vast-tag'
            noFalse
            unlicensed={isUnlicensedOrDisabled}
            disabled={isUnlicensedOrDisabled}
            datacy="card-creative-type"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <CardSmall
            title='Audio'
            actionText="MP3, AAC, WAV, OGG, OGV, QuickTime (QT)"
            icon={<AudioAd className={classes.icon} />}
            isSelected={selected === 'audio'}
            handleSelected={handleSelected}
            field='audio'
            noFalse
            unlicensed={isUnlicensedOrDisabled}
            disabled={isUnlicensedOrDisabled}
            datacy="card-creative-type"
          />
        </Grid>
        <Grid item md={3} xs={12}></Grid>
      </Grid>


      <Typography variant='h2' className={classes.subtitle}>
        Integrated Ad Formats
      </Typography>

      <Grid container spacing={2}>

        <Grid item md={3} xs={12}>
          <CardSmall
            title='Native Ads'
            actionText="Create with text and JPEG, JPG or PNG images."
            icon={<NativeAd className={classes.icon} />}
            isSelected={selected === 'native'}
            handleSelected={handleSelected}
            field='native'
            noFalse
            datacy="card-creative-type"
          />
        </Grid>

        {/* Empty Grid Items for spacing */}
        <Grid item md={3} xs={12}></Grid>
        <Grid item md={3} xs={12}></Grid>
        <Grid item md={3} xs={12}></Grid>
      </Grid>


      <Grid container justify="center">
        <Grid item>
          <ButtonContained
            label="Next"
            className={classes.submitButton}
            style={{ marginTop: 30, marginBottom: 24, width: 150 }}
            onClick={submitStep}
            datacy="button-creative-type"
          />
        </Grid>
      </Grid>
    </Grid >
  );
}
export default CreativesSelectType;
