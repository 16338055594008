import React from 'react';
import { useStyles } from './styles';
import {
  Grid,
  Typography,
  Button
} from '@material-ui/core';

const ErrorFallback = ({error, resetErrorBoundary}) => {
  const classes = useStyles();

  return (
    <Grid container role="alert" direction='column' alignItems='center' justify='center' className={classes.root}>

      <Typography variant='h4' color='primary' style={{marginBottom: 20}}>Oh no! Something went wrong...</Typography>
      {(!process.env.REACT_APP_ENV || (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() !== 'production')) &&
        <Typography variant='h6' style={{color: 'red'}}>{error.message}</Typography>
      }

      <Button
        onClick={resetErrorBoundary}
        variant='outlined'
        color='primary'
        >
        Try again
      </Button>
    </Grid>
  );
};

export default ErrorFallback;
