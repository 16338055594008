import React from 'react';
import { useStyles } from './styles';
import { Grid, Typography } from '@material-ui/core';
import Close from 'icons/ui/Close';

const FilenameCardSmall = ({
  index,
  name,
  handleRemoveFile
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={4}>
      <Grid container alignItems='center' style={{width: 200}}>
        <Grid item xs={1}>
          <Close className={classes.deleteIcon} onClick={() => handleRemoveFile(index)}/>
        </Grid>
        <Grid item xs={11}>
        <Typography className={classes.fileName} style={{width: 150}} noWrap>{name}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilenameCardSmall;
