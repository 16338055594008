const nativeCreative = {
    advertiser_id: 0,
    audit_status: "no_audit",
    allow_audit: "false",
    description: "string",
    state: "inactive",
    template: {
        id: 39461
    },
    native_attribute: {
        link: {
            url: "string"
        },
        data_assets: [{
            data_type: "sponsored_by",
            value: "#sponsoredNative"
        }, {
            data_type: "title",
            value: "#titleNative"
        }, {
            data_type: "call_to_action",
            value: "#ctaNative"
        }, {
            data_type: "description",
            value: "#bodyTextNative"
        }],      
        image_assets: [
            {
                image_type: "icon_image",
                media_asset_id: 0,
                creative_asset_image: {
                    file_name: "string",
                    content: "string",
                    url_secure: "string"
                }
            }
        ]
    },
}
  
export default nativeCreative;