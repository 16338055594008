import { createSlice } from '@reduxjs/toolkit';

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState: { value: [], isRetrieved: false },
  reducers: {
    setCampaigns: (state, action) => {
      state.value = action.payload;
      state.isRetrieved = true;
    },
    clearCampaigns: (state) => {
      state.value = [];
    },
    setRetrieved: (state, action) => {
      state.isRetrieved = action.payload;
    },
  },
});

export const { setCampaigns, clearCampaigns, setRetrieved } = campaignsSlice.actions;

export const selectCampaigns = (state) => state.campaigns;

export default campaignsSlice.reducer;