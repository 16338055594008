import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  margin: {
    // margin: theme.spacing(1),
  },
  selectRoot: {
    color: '#0582CA',
    fontFamily: "Poppins",
    fontSize: 18,
    width: '100%'
  },
  option: {
    color: "#051923",
    fontSize: 16,
  },
  label: {
    zIndex: 100,
    top: -10,
    left: 14,
    color: '#A7A7A7',
    fontSize: 16,
  },
  staticLabel: {
    fontSize: 12,
    fontFamily: 'Open Sans',
    lineHeight: "16.34px",
    fontWeight: 700,
    marginBottom: 3,
    marginLeft: 1,
    color: '#051923'
  },
  bottomLabel: {
    fontSize: 16,
    fontFamily: 'Open Sans',
    lineHeight: "16.34px",
    fontWeight: 700,
    marginTop: 10,
    marginLeft: 1,
    color: '#051923'
  }
}));
