import SvgIcon from '@material-ui/core/SvgIcon';

export default function Haken(props) {
    return (
      <SvgIcon {...props}>
        <path 
         d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" 
         />
      </SvgIcon>
    );
  }