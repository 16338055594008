import React from 'react';
import { useStyles } from './styles';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import CreativesSelectType from 'components/creatives/CreativesSelectType';
import InformationPanel from 'components/elements/InformationPanel';
import BannerHelpText from './BannerHelpText';
import NativeHelpText from './NativeHelpText';
import VideoHelpText from './VideoHelpText';
import AudioHelpText from './AudioHelpText';
import HTML5HelpText from './HTML5HelpText';
import AdTagHelpText from './AdTagHelpText';
import VideoTagHelpText from './VideoTagHelpText';
import UrlTagHelpText from './UrlTagHelpText';
import InsertMacroHelpText from './InsertMacroHelpText';


import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';

const helpTitles = {
  'banner': 'Recommended Banner Ad Sizes',
  'native': 'Creating Native Ads',
  'video': 'Video Ads Requirements',
  'audio': 'Audio Ads Requirements',
  'html5': 'Recommended Banner Ad Sizes',
  'adTag': 'Add macros to third-party display ad tags',
  'vastTag': 'Troubleshooting VAST Creative Display Issues',
  'urlTag': 'URL Tag Service',
  'InsertMacro': 'Ad Tag Detection and Macro Insertion Service',
}


const infoPanelLink = (creativeType) => {
  switch (creativeType) {
    case 'adTag':
      return {
        link: "/creatives/create/macro-check",
        buttonLabel: "Macro Insertion Service",
        onLearnMore: (history) => history.push('/creatives/create/macro-check')
      };
    // Add more cases as needed for different creative types
    default:
      return {
        link: "https://adpersonam.tawk.help/",
        buttonLabel: "Learn more",
        onLearnMore: null // No custom action, will open the link in a new tab
      };
  }
};

const CreativesCreate = ({
  creativeType,
  children,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { link, buttonLabel, onLearnMore } = infoPanelLink(creativeType);

  useHubSpotHook('/creatives/create');

  const helpTextSwitch = (data) => {
    switch (data) {
      case 'banner':
        return <BannerHelpText />;
      case 'native':
        return <NativeHelpText />;
      case 'video':
        return <VideoHelpText />;
      case 'audio':
        return <AudioHelpText />;
      case 'html5':
        return <HTML5HelpText />;
      case 'adTag':
        return <AdTagHelpText />;
      case 'vastTag':
        return <VideoTagHelpText />;
      case 'urlTag':
        return <UrlTagHelpText />;
      case 'InsertMacro':
        return <InsertMacroHelpText />;
      default:
        return <BannerHelpText />;
    }
  };


  return (
    <Grid container direction='column' className={classes.root}>
      <Grid container direction='row'>
        <Grid item xs={12} md={9}>
          <Typography variant='h1' className={classes.header}>Creatives</Typography>
          <Typography variant='subtitle1' className={classes.subtitle}>
            Simply upload your creative assets directly to our platform and start building visually engaging campaigns.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className={classes.cancelCont}>
          <Link to='/snippets'>
            <Typography variant='subtitle1' className={classes.cancelText}>Cancel</Typography>
          </Link>
        </Grid>
      </Grid>

      <Grid container direction='row' style={{ marginTop: 42 }}>
        <Grid item xs={12} md={creativeType ? 8 : 11}>
          <Grid container className={classes.subCont}>

            <Grid item xs={2} className={classes.leftNav}>
              <Typography variant='subtitle1' className={!creativeType ? classes.stepSelected : classes.step}>
                1 Asset type
              </Typography>
              <Typography variant='subtitle1' className={creativeType ? classes.stepSelected : classes.step}>
                2 Creative
              </Typography>
            </Grid>
            <Grid item xs={10} className={classes.rightPanel}>
              {!creativeType ?
                <CreativesSelectType />
                :
                children
              }
            </Grid>

          </Grid>
        </Grid>

        {creativeType &&
          <Grid item xs={12} md={4} className={classes.infoCont}>
            <InformationPanel
              title={helpTitles[creativeType]}
              text={helpTextSwitch(creativeType)}
              link={link}
              buttonLabel={buttonLabel}
              onLearnMore={onLearnMore}
            />
          </Grid>
        }
      </Grid>
    </Grid>
  );
};

export default CreativesCreate;
