import React, { useRef } from 'react';
import { useStyles } from './styles';
import { Grid, Typography } from '@material-ui/core';
import Plus1 from 'icons/ui/Plus1';
import { toast } from "react-toastify";

const UploadButton = ({
  title,
  subtitle,
  style,
  isSelected,
  label,
  handleAddButton,
  datacy,
  isDisabled
}) => {
  const classes = useStyles();
  const fileInput = useRef();

  const handleFile = (e) => {
    handleAddButton(e.target.files[0]);
    e.target.value = null;
  };

  const onClick = () => {
    if (isDisabled) {
      toast.error('Only one file can be uploaded!', {
        progressClassName: 'toastProgressError'
      });
      return;
    }
    fileInput.current.click();
  }

  //console.log('isDisabled', isDisabled);
  return (
    <Grid
      container
      direction='column'
      style={style}
      onClick={onClick}
    >
      {label &&
        <Typography className={classes.label}>{label}</Typography>
      }
      <Grid container wrap="nowrap" alignItems="center"
        className={classes.root}
      >
        <Plus1 className={classes.icon} />
        <Grid container direction='column'>
          <Typography
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography
            className={classes.subtitle}
          >
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
      <input
        ref={fileInput}
        type="file"
        onChange={handleFile}
        hidden
        disabled={isDisabled}
        data-cy={datacy}
      />
    </Grid>
  );
};

export default UploadButton;
