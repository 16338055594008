import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { Grid, Typography, TextField } from '@material-ui/core';
import TextFieldStyled from 'components/elements/TextFieldStyled';
import RadioStyled from 'components/elements/RadioStyled';
import ButtonContained from 'components/elements/ButtonContained';
import SelectStyled from 'components/elements/SelectStyled';
import creative3rdParty from 'utils/models/creative3rdParty';
import creativeSize from "../../../utils/consts/creativeSize";
import { useGetSelectedAdvertiser } from 'utils/hooks/advertiserHooks';
import CreativesCreate from 'containers/CreativesCreate';
import BackButtonOutlined from 'components/elements/BackButtonOutlined';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import { trackEvent } from 'utils/hooks/analyticsG4';
import sendSlackMessage from 'utils/functions/sendSlackMessage';
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';
import * as Sentry from "@sentry/react";

const JsTagCreate = () => {
    const classes = useStyles();
    const [activeCreative, setActiveCreative] = useState(creative3rdParty);
    const [tagType, setTagType] = useState('HTML'); // Default to HTML
    const [contentText, setContentText] = useState('');
    const [creativeName, setCreativeName] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const { selectedAdvertiser } = useGetSelectedAdvertiser();
    const history = useHistory();
    const [isUploading, setIsUploading] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const options = creativeSize.map(size => ({
        label: size.label,
        value: `${size.width}x${size.height}`
    }));

    //console.log('creativeSize:', creativeSize);
    //console.log('options:', options);

    useHubSpotHook('/creatives/create/banner');

    useEffect(() => {
        setCanSubmit(false);
        if (
            contentText &&
            creativeName &&
            width &&
            height
        ) {
            setCanSubmit(true);
        }
    }, [contentText, creativeName, width, height]);

    const handleTagRadio = (selectedType) => {
        setTagType(selectedType);
    };


    const handleUpload = async () => {
        setIsUploading(true);
        const toastId = toast('Your tag is being uploaded...', {
            progressClassName: 'toastProgressHidden'
        });

        const createCreative = async () => {
            let enclosedDocument = contentText;

            const escapeAndWrap = (content) => {
                let escapedContent = content
                    .replace(/\\/g, '\\\\')  // Escape backslashes
                    .replace(/"/g, '\\"')    // Escape double quotes
                    .replace(/<\/script>/g, '<\\/script>') // Escape the closing script tag
                    .replace(/\n/g, '');    // Remove newlines

                return `document.write('${escapedContent}');`;
            }

            // Check if tagType is 'HTML' and wrap contentText in document.write();
            if (tagType === 'HTML') {
                enclosedDocument = escapeAndWrap(contentText);
            }

            const templateId = tagType === 'HTML' ? 6 : 5;
            // template id 6 is HTML, template id 5 is Javascript
            const encodedCreative = {
                ...activeCreative,
                advertiser_id: selectedAdvertiser.id,
                content: enclosedDocument,
                content_secure: enclosedDocument,
                original_content: contentText,
                original_content_secure: contentText,
                name: creativeName,
                width: Number(width),
                height: Number(height),
                template: {
                    id: templateId  // Nested object to hold template ID
                }
            };

            //console.log('Encoded Creative:', encodedCreative);

            try {
                const createdCreative = await axiosClient.post(`/creative`, encodedCreative);
                return createdCreative;
            } catch (err) {
                console.log(err.response);
                Sentry.captureException(err);
                setIsUploading(false);
                return { error: creativeName };
            }
        };

        const runFiles = async () => {
            const createdCreative = await createCreative();
            if (createdCreative.error) {
                toast.dismiss(toastId);
                toast.error(`There was a problem uploading ${createdCreative.error}`, {
                    progressClassName: 'toastProgressError'
                });
                Sentry.captureMessage(createdCreative.error);
                return;
            }
            sendSlackMessage(`:white_check_mark: A new ad tag has been uploaded id: ${createdCreative.data.id}`);
        };

        // Running the runFiles function
        await runFiles();

        setIsUploading(false);
        toast.dismiss(toastId);
        toast.success('Your creatives have been uploaded!', {
            progressClassName: 'toastProgressSuccess'
        });
        trackEvent({
            category: 'Snippet',
            action: 'select_item',
            label: "app.adpersonam.io"
        });
        history.push('/snippets');
    };

    // Function to update the text content state
    const updateContentText = (field, e) => {
        const value = e.target.value;
        switch (field) {
            case 'content':
                setContentText(value);
                break;
            case 'creativeName':
                setCreativeName(value);
                break;
            case 'creativeSize':
                //console.log("Creative size raw value:", e.target.value);
                const [width, height] = value.split('x').map(Number);
                //console.log(`Parsed width: ${width}, height: ${height}`);  // Debug line
                setWidth(width);
                setHeight(height);
                setActiveCreative({
                    ...activeCreative,
                    width,
                    height
                });
                break;
            default:
                console.error("Unhandled field:", field);
                Sentry.captureException(new Error(`Unhandled field: ${field}`));
                break;
        }
        // This will handle all other fields like 'content' and 'creativeName'
        if (field !== 'creativeSize') {
            setActiveCreative({
                ...activeCreative,
                [field]: value
            });
        }
    };

    return (
        <CreativesCreate creativeType="adTag">
            <Grid container direction='column' className={classes.root}>
                <Typography variant='h2' className={classes.title}>Upload 3rd Party Tags</Typography>

                <Grid container direction='column' style={{ marginTop: 24 }}>
                    <Typography variant='subtitle1' className={classes.subtitle}>
                        Select Tag Type
                    </Typography>
                    <Grid container direction='row' justifyContent='space-between' style={{ width: '50%' }}>
                        <RadioStyled
                            names={['HTML', 'Javascript']}
                            labels
                            color='#0582CA'
                            handleSelected={handleTagRadio}
                            datacy="radio-targeting-audio-video"
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems='flex-end'>
                </Grid>
                <TextFieldStyled
                    staticLabel="Enter name of the creative"
                    placeholder="Creative Name"
                    //sublabel="The landing page must match the language, brand, and offer of the creative"
                    style={{ marginTop: 20, marginBottom: 20 }}
                    handleUpdate={(e) => updateContentText('creativeName', e)}
                    field="creativeName"
                    disabled={isUploading}
                    datacy="input-banner-click-url"
                />

                <TextField
                    id="filled-textarea"
                    multiline
                    minRows={5}
                    variant="outlined"
                    placeholder={tagType === "HTML" ? "Enter HTML code here...\n\nCreative that starts and ends with HTML components, even if these HTML components are <script> tags." : "Enter some code here...\n\nCreative that starts and ends with Javascript components, even if the Javascript code writes HTML."}
                    field="content"
                    style={{ marginTop: 10 }}
                    InputProps={{
                        classes: {
                            input: classes.resize,
                        },
                    }}
                    onChange={(e) => updateContentText('content', e)}
                />
                <SelectStyled
                    staticLabel="Select Creative Size"
                    style={{ marginTop: 20, width: '60%' }}
                    options={options}
                    handleUpdate={(e) => updateContentText('creativeSize', e)}
                    field="creativeSize"
                    noNone
                    datacy="select-optimization-creative-type"
                />
                <Grid>
                </Grid>

                {isUploading ?
                    <Typography variant='h6' className={classes.uploadingText}>......</Typography>
                    :
                    <Grid container className={classes.buttons}>
                        <BackButtonOutlined label="Back" />
                        <ButtonContained
                            label="Upload"
                            style={{ marginLeft: 10 }}
                            onClick={handleUpload}
                            disabled={!canSubmit}
                            datacy="button-submit-banner"
                        />
                    </Grid>
                }
            </Grid>
        </CreativesCreate>
    );
};
export default JsTagCreate;