const domainsQueryFilter = (domainsArray, country) => {
    let regex;
    switch (country) {
        case 'Italy':
            regex = /(it\..+)|(\.it$)|\/it/;
            break;
        case 'Germany':
            regex = /(de\..+)|(\.de$)|\/de/;
            break;
        case 'United Kingdom':
            regex = /(uk\..+)|(\.co\.uk$)|\/uk|(\.com$)/;
            break;
        case 'Brazil':
            regex = /(br\..+)|(\.com\.br$)|\/br|(\.com$)/;
            break;
        case 'Netherlands':
            regex = /(nl\..+)|(\.nl$)|\/nl/;
            break;
        case 'Ireland':
            regex = /(ie\..+)|(\.ie$)|\/ie|(\.com$)|(\.co\.uk$)/;
            break;
        case 'Canada':
            regex = /(ca\..+)|(\.ca$)|\/ca|(\.com$)/;
            break;
        case 'New Zealand':
            regex = /(nz\..+)|(\.co\.nz$)|\/nz|(\.com$)/;
            break;
        case 'Chile':
            regex = /(cl\..+)|(\.cl$)|\/cl|(\.com$)/;
            break;
        case 'Argentina':
            regex = /(ar\..+)|(\.com\.ar$)|\/ar|(\.com$)/;
            break;
        case 'Japan':
            regex = /(jp\..+)|(\.jp$)|\/jp|(\.com$)/;
            break;
        case 'Poland':
            regex = /(pl\..+)|(\.pl$)|\/pl/;
            break;
        case 'France':
            regex = /(fr\..+)|(\.fr$)|\/fr/;
            break;
        case 'Finland':
            regex = /(fi\..+)|(\.fi$)|\/fi/;
            break;
        case 'Australia':
            regex = /(au\..+)|(\.com\.au$)|\/au|(\.com$)/;
            break;
        case 'United States':
            regex = /(us\..+)|(\.us$)|\/us|(\.com$)/;
            break;
        case 'Switzerland':
            regex = /(ch\..+)|(\.ch$)|\/ch|(\.com$)|(\.de$)|(\.fr$)|(\.it$)/;
            break;
        default:
            //console.log(`Filtering not set for ${country}. Displaying all domains.`);
            return domainsArray;
    }

    return domainsArray.filter(domain => regex.test(domain));
};

export default domainsQueryFilter;




















