import { useStyles } from './styles';
import {
  Typography,
} from '@material-ui/core';

export default function VideoHelpText() {
  const classes = useStyles();

  return (
    <div>
      <ul>
        <li><Typography className={classes.helpText}><b>Max Video File Size:</b> 10MB</Typography></li>
        <li><Typography className={classes.helpText}><b>Minimum Bit Rate:</b> 2.500 kbps</Typography></li>
        <li><Typography className={classes.helpText}><b>Minimum Pixel Size:</b> 1280x720px</Typography></li>
        <li><Typography className={classes.helpText}><b>File Types:</b> mp4</Typography></li>
      </ul>
      <Typography className={classes.helpText}>
        <b>Recommended CTV Video Creative Specifications</b>
      </Typography>
      <ul>
        <li><Typography className={classes.helpText}><b>Minimum Bitrate:</b> 2,000 kbps</Typography></li>
        <li><Typography className={classes.helpText}><b>Minimum Resolution:</b> 1280×720 pixels (sometimes notated as 720p)</Typography></li>
        <li><Typography className={classes.helpText}><b>Minimum Pixel Size:</b> 1280x720px</Typography></li>
        <li><Typography className={classes.helpText}><b>Duration:</b> 15 or 30 seconds</Typography></li>
      </ul>

    </div>
  );
}