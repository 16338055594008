import { useState } from 'react';
import { useStyles } from './styles';
import { InputLabel, InputBase, FormControl, Select, Typography, Grid, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ArrowDown from 'icons/ui/ArrowDown';
import clsx from "clsx";
import TooltipStyled from '../TooltipStyled';
import ArrowRight from 'icons/ui/ArrowRight';
import { useEffect } from 'react';
import axiosClient from 'utils/axiosClient';
import * as Sentry from "@sentry/react";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      // marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 5,
    // width: 151,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #D0D0D0',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 5,
      border: '2px solid',
      borderColor: '#0582CA',
    },
  },
}))(InputBase);

const iconStyles = {
  selectIcon: {
    fontSize: 36,
    top: 6,
    color: "#0582CA"
  }
};
const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <ArrowDown
        {...rest}
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);

export default function SelectStyled({
  initialSelected,
  style,
  disabled,
  options,
  staticLabel,
  placeholder,
  handleUpdate,
  required,
  field,
  tooltip,
  unlicensed,
  noNone,
  datacy,
}) {
  const classes = useStyles();
  const newOptions = options ? options : ['Ten', 'Twenty', 'Thirty'];
  const [selected, setSelected] = useState(initialSelected ? initialSelected : "");

  useEffect(() => {
    if (initialSelected) {
      setSelected(initialSelected)
    }
  }, [initialSelected])

  const handleChange = (e) => {
    e.target.id = field;
    setSelected(e.target.value);
    handleUpdate(e);
  };

  const manageProfile = () => {
    axiosClient.post(
      `/checkout/manage`
    ).then(gotManage => {
      if (gotManage.data) {
        window.open(gotManage.data, "_self")
      }
    })
      .catch(err => {
        console.log('Err Getting Profile Sub Manage:', err.response);
        Sentry.captureException(err);
      });
  }

  return (
    <Grid container direction='column' style={{ width: 'auto', ...style }}>

      {staticLabel &&
        <Grid container direction='row' style={{ position: 'relative' }}>
          <Typography
            variant='caption'
            className={classes.staticLabel}
            style={{ color: disabled ? '#D0D0D0' : '#051923' }}
          >{staticLabel}
          </Typography>
          {tooltip &&
            <TooltipStyled
              title={tooltip}
              style={{ position: 'absolute', right: 0, top: -5 }}
            />
          }
        </Grid>
      }
      <FormControl className={classes.margin}>
        <InputLabel className={classes.label} shrink={false}>
          {selected === '' ? placeholder : ""}
        </InputLabel>
        <Select
          id={field}
          value={selected}
          onChange={handleChange}
          disabled={disabled}
          input={<BootstrapInput />}
          IconComponent={CustomExpandMore}
          className={classes.selectRoot}
          required={required}
          // inputProps={{ 'data-cy': datacy }}
          data-cy={datacy}
        >
          {!noNone &&
            <MenuItem aria-label="None" value="">None</MenuItem>
          }
          {newOptions.map((option, key) => (
            <MenuItem
              key={key}
              value={option.value}
              name={option.label}
              disabled={option.disabled}
              className={classes.option}
              style={{
                fontWeight: selected === option ? 'bold' : 'normal',
                color: option.disabled ? '#e0e0e0' : '#050A23',
              }}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {unlicensed &&
        <Grid container direction='row' alignItems='center' justifyContent='flex-start'
          className={classes.unlockRow}
          onClick={manageProfile}
        >
          <i className={`${"fas fa-lock"} ${classes.icon}`}></i>
          <Typography variant='caption' className={classes.actionText}>upgrade to Pro</Typography>
          <ArrowRight className={classes.arrow} />
        </Grid>
      }
    </Grid>
  );
}