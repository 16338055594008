import { createSlice } from '@reduxjs/toolkit';

export const newCampaignSlice = createSlice({
  name: 'newCampaign',
  initialState: { value: [], isRetrieved: false },
  reducers: {
    setNewCampaign: (state, action) => {
      state.value = action.payload;
      state.isRetrieved = true;
    },
    clearNewCampaign: (state) => {
      state.value = [];
    },
    setRetrieved: (state, action) => {
      state.isRetrieved = action.payload;
    },
  },
});

export const { setNewCampaign, clearNewCampaign, setRetrieved } = newCampaignSlice.actions;

export const selectNewCampaign = (state) => state.newCampaign;

export default newCampaignSlice.reducer;