import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.primary.main, // Updated to use the primary color
        boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.15)',
        paddingTop: 20,
        paddingLeft: 10,
        paddingRight: 10,
        position: 'relative',
        height: 400 // Set a fixed height
    },
    linkButton: {
        textAlign: 'left',
        justifyContent: 'flex-start',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
        border: `1px solid ${theme.palette.grey.dark}`,
        '&:hover': {
            backgroundColor: theme.palette.highlight.a1,
            color: '#ffffff',
        },
    },
    boxSidebar: { // New class
        backgroundColor: theme.palette.white, // White background color
    },
    icon: { // Common styles for icons
        color: theme.palette.grey.light,
        borderRadius: 15,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
    AccordionDetail: {
        background: theme.palette.primary.main,
    },
    AccordionSummary: {
        background: theme.palette.primary.main,
    },
    arrow: {
        color: '#0582CA',
        fontSize: 32,
    }
}));

export { useStyles };


