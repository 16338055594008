import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: 20,
    paddingTop: 50,
    boxShadow: '0px 8px 25px 0px #0000001A',
    background: '#fff',
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: 15,
    width: '90%',
    paddingBottom: 42,
  },
  bulbIcon: {
    position: 'absolute',
    top: -30,
    left: 20,
    background: '#fff',
    padding: 10,
    fontSize: 42,
    borderRadius: 66,
    boxShadow: '0px 8px 25px 0px #0000001A',
  },
  helpTextTitle: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '24px',
    color: '#0582CA',
  },
  helpText: {
    marginTop: 15,
    color: '#051923',
    fontFamily: 'Poppins',
    fontWeight: 100,
    fontSize: 14,
    lineHeight: '21.79px',
  }
}));
