import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  NativeSelect,
  InputBase,
  Tabs,
  Tab,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useGetSelectedAdvertiser } from 'utils/hooks/advertiserHooks';
import { useGetAdvertisers } from 'utils/hooks/advertiserHooks';
import { setSelectedAdvertiser } from 'redux/slices/selectedAdvertiser';
import { useDispatch } from 'react-redux';
import ArrowDown from 'icons/ui/ArrowDown';
import subBarCampaignsRoutes from 'utils/consts/subBarCampaignsRoutes';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
    },
  },
  input: {
    width: 151,
    height: 38,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #D0D0D0',
    borderTop: '0px',
    borderBottom: '0px',
    fontSize: 16,
    paddingLeft: 10,
    fontWeight: 'bold',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))(InputBase);

const iconStyles = {
  selectIcon: {
    fontSize: 36,
    top: 7,
    color: "#0582CA"
  }
};
const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <ArrowDown
        {...rest}
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);

const AntTabs = withStyles({
  indicator: {
    backgroundColor: '#05CAB0',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    // minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#05CAB0',
      opacity: 1,
    },
    '&$selected': {
      color: '#051923',
      fontWeight: 'bold',
    },
    '&:focus': {
      color: '#051923',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const routeMap = {
  0: '/snippets',
  1: '/campaigns',
  2: '/reports',
  3: '/advertiser-settings'
}

const SubTopBar = props => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const { selectedAdvertiser } = useGetSelectedAdvertiser();
  const { advertisers } = useGetAdvertisers();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = React.useState(0);

  useEffect(() => {
    if (!selectedAdvertiser) {
      history.push('/');
    }
  }, [selectedAdvertiser])

  useEffect(() => {
    const splitPathname = history.location.pathname.split('/');
    const truncatedPathname = (splitPathname[1] && splitPathname[2])
      ? "/" + splitPathname[1] + "/" + splitPathname[2]
      : "";

    if (history.location.pathname === '/snippets') {
      setSelectedTab(0);
    } else if (
      subBarCampaignsRoutes.includes(history.location.pathname)
      || subBarCampaignsRoutes.includes(truncatedPathname)
    ) {
      setSelectedTab(1);
    } else if (history.location.pathname === '/reports') {
      setSelectedTab(2);
    } else if (history.location.pathname === '/advertiser-settings') {
      setSelectedTab(3);  // Setting to the new tab index for 'Advertiser Settings'
    }
  }, [history.location.pathname]);


  const handleChange = (e) => {
    const selected = advertisers.filter(a => a.id.toString() === e.target.value);
    dispatch(setSelectedAdvertiser(selected[0]));
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    history.push(routeMap[newValue]);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.barRoot}
        style={{ background: '#fff' }}
      >
        <Toolbar className={classes.toolbar}>
          <NativeSelect
            id="advertiserSelect"
            value={selectedAdvertiser?.id}
            onChange={handleChange}
            input={<BootstrapInput />}
            IconComponent={CustomExpandMore}
            className={classes.selectRoot}
          >
            {advertisers.map((advertiser, key) => (
              <option
                key={key}
                value={advertiser.id}
                className={classes.option}
                style={{ fontWeight: selectedAdvertiser?.id === advertiser.id ? 'bold' : 'normal' }}>
                {advertiser.name}
              </option>
            ))}
          </NativeSelect>

          {!isMobile &&
            <AntTabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
              className={classes.tabs}
            >
              <AntTab label="Snippets" id="snippets" className={classes.tab} />
              <AntTab label="Campaigns" id="campaigns" className={classes.tab} />
              <AntTab label="Reports" className={classes.tab} data-cy="tab-sub-top-bar-reports" />
              <AntTab label="Settings" id="advertiser-settings" className={classes.tab} />
            </AntTabs>
          }

        </Toolbar>
      </AppBar>
    </div>
  );
};

export default SubTopBar;
