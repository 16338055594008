import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  summary: {
    paddingLeft: 0,
  },
  title: {

  },
  icon: {
    color: '#0582CA',
  },

}));
