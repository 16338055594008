import React, { useEffect, useState } from 'react';
import { useGetOwnUser, useGetUserSubLevel } from 'utils/hooks/userHooks';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { Grid, Typography } from '@material-ui/core';
import Close from 'icons/ui/Close';
import ArrowRight from 'icons/ui/ArrowRight';
import axiosClient from 'utils/axiosClient';
import * as Sentry from "@sentry/react";


const CardSmall = ({
  title,
  description,
  actionText,
  style,
  isSelected,
  disabled,
  xIcon,
  icon,
  handleSelected,
  field,
  noFalse,
  unlicensed,
  datacy,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(isSelected);
  const history = useHistory();
  const { userData } = useGetOwnUser();
  const { hasStarter } = useGetUserSubLevel();

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected])

  const handleChange = () => {
    if (!disabled) {
      if (noFalse) {
        if (!selected) {
          setSelected(true);
          handleSelected(field, true);
        }
      } else {
        setSelected(!selected);
        handleSelected(field, !selected);
      }
    }
  }
  // Define the handleSubscriptionRedirect function inside InfoCampaignPanel
  const handleSubscriptionRedirect = () => {
    const hasPlan = !!userData && !!userData["custom:subscription"];

    if (!hasPlan) {
      history.push('/subscribe');
    } else if (hasStarter) {
      manageProfile();
    }
    // Return true if redirection or profile management is initiated, false otherwise
    if (!hasPlan || (hasStarter)) {
      return true; // Indicating redirection or action is taken
    }
    return false;
  };

  // Define manageProfile function
  const manageProfile = () => {
    axiosClient.post(`/checkout/manage`)
      .then(response => {
        if (response.data) {
          window.open(response.data, "_self");
        }
      })
      .catch(err => {
        console.error('Error Getting Profile Subscription Management:', err.response);
        Sentry.captureException(err);
      });
  };

  return (
    <Grid container direction='column' alignItems='center'
      className={classes.root}
      onClick={handleChange}
      id={field}
      style={{
        borderWidth: selected ? 3 : 1,
        borderColor: selected ? '#0582CA' : '#A7A7A7',
        padding: selected ? '18px 10px' : '20px 15px',
        maxHeight: actionText ? null : xIcon ? 96 : 65,
        ...style,

      }}
      data-cy={datacy}
    >
      {icon && icon}
      {xIcon &&
        <Close className={classes.xIcon} />
      }
      <Typography
        className={classes.title}
        style={{ color: disabled ? '#A7A7A7' : '#050A23' }}
      >
        {title}
      </Typography>
      <Typography
        className={classes.description}
        style={{
          color: '#050A23',
          marginTop: selected ? 4 : 3,
          marginBottom: selected ? 5 : 6,
        }}
      >
        {description}
      </Typography>
      {actionText &&
        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='caption' className={classes.actionText}>{actionText}</Typography>
        </Grid>
      }
      {unlicensed &&
        <Grid container direction='row' alignItems='center' justifyContent='center' // Adjusted to center
          className={classes.unlockRow}
          onClick={() => handleSubscriptionRedirect()}
        >
          <i className={`${"fas fa-lock"} ${classes.icon}`}></i>
          <Typography variant='caption' className={classes.actionText}>upgrade</Typography>
          <ArrowRight className={classes.arrow} />
        </Grid>
      }
    </Grid>
  );
};

export default CardSmall;
