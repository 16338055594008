const getImageDimensions = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
            const dimensions = {width: img.width, height: img.height};
            resolve(dimensions);
        };
    };
    reader.onerror = error => reject(error);
});

export default getImageDimensions;