import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: 5,
    width: 'auto',
    // '&:hover': {
    //   "& span": {
    //     fontWeight: 700,
    //   }
    // }
  },
  staticLabel: {
    fontSize: 12,
    fontFamily: 'Open Sans',
    lineHeight: "16.34px",
    fontWeight: 700,
    marginBottom: 4,
    marginLeft: 4,
    color: '#051923'
  },
  label: {
    fontSize: 16,
    fontFamily: 'Open Sans',
    lineHeight: "21.79px",
    fontWeight: 400,
    marginBottom: 3,
    marginLeft: 1,
    color: '#051923',
    position: 'relative',
    bottom: 1,
  },
  icon: {
    color: theme.palette.primary.main
  }
}));
