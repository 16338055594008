import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user';
import advertisersReducer from './slices/advertisers';
import selectedAdvertiserReducer from './slices/selectedAdvertiser';
import creativesReducer from './slices/creatives';
import universalPixelsReducer from './slices/universalPixels';
import pixelsReducer from './slices/pixels';
import audiencesReducer from './slices/audiences';
import domainsReducer from './slices/domains';
import profileReducer from './slices/profile';
import campaignsReducer from './slices/campaigns';
import newCampaignReducer from './slices/newCampaign';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 

const persistConfig = {
  key: 'base',
  version: 1,
  storage,
}

const rootReducer = combineReducers({
  user: userReducer,
  advertisers: advertisersReducer,
  selectedAdvertiser: selectedAdvertiserReducer,
  creatives: creativesReducer,
  pixels: pixelsReducer,
  audiences: audiencesReducer,
  domains: domainsReducer,
  profile: profileReducer,
  campaigns: campaignsReducer,
  universalPixels: universalPixelsReducer,
  newCampaign: newCampaignReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});