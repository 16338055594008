import { useStyles } from './styles';
import {
  Typography,
} from '@material-ui/core';

export default function AdTagHelpText() {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.helpText}>
        ad:personam is compatible with a wide range of third-party ad servers, enabling seamless integration with various advertising ecosystems. To facilitate effective tracking and optimization, ad:personam DSP supports the use of various click tracking and cache busting macros. These macros play a crucial role in allowing third-party servers to accurately track clicks and other relevant interactions for display creatives served through our platform.
      </Typography>

    </div>
  );
}