import { createSlice } from '@reduxjs/toolkit';

export const creativesSlice = createSlice({
  name: 'creatives',
  initialState: { value: [], isRetrieved: false },
  reducers: {
    setCreatives: (state, action) => {
      state.value = action.payload;
      state.isRetrieved = true;
    },
    clearCreatives: (state) => {
      state.value = [];
    },
    setRetrieved: (state, action) => {
      state.isRetrieved = action.payload;
    },
  },
});

export const { setCreatives, clearCreatives, setRetrieved } = creativesSlice.actions;

export const selectCreatives = (state) => state.creatives;

export default creativesSlice.reducer;