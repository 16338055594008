import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: '#fff',
    padding: '32px 70px 32px 0px',
    [theme.breakpoints.down("sm")]: {
      padding: '32px 31px 32px 31px',
    },
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '36px',
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16.34px',
    marginTop: 30,
    marginBottom: 10,
  },
  buttons: {
    marginTop: 30,
  }
}));
