import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  filesCont: {
    height: 75,
    paddingLeft: 10,
    position: 'relative',
    top: 2,
  }
}));
