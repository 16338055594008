import { Auth } from "aws-amplify";
import axios from "axios";

const urls = {
  dev: "https://y6gmrvkxik.execute-api.us-east-2.amazonaws.com/dev",
  prod: "https://mr1grnjy5j.execute-api.us-east-2.amazonaws.com/prod"
}

const baseURL = process.env.REACT_APP_ENV ? urls[process.env.REACT_APP_ENV] : urls['dev'];

const instance = axios.create({
  baseURL
});

instance.interceptors.request.use(config => {
  async function attachToken() {
    config.headers.Authorization = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
    return config;  
  }
  return attachToken();
});

export default instance;
