import React from 'react';
import { useStyles } from './styles';
import { Grid, Radio, Typography } from '@material-ui/core';
import RadioEmpty from 'icons/ui/RadioEmpty';
import RadioActive from 'icons/ui/RadioActive';
import TooltipStyled from '../TooltipStyled';

const RadioStyled = ({
  checked,
  labels,
  style,
  color,
  names,
  selected,
  handleSelected,
  staticLabel,
  tooltip,
  disabled,
  datacy,
  editing
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState(selected);

  const handleChange = (event) => {
    setSelectedValue(event.target.name);
    handleSelected(event.target.name);
  };

  return (
    <>
      {staticLabel &&
        <Grid container direction='row' style={{ position: 'relative' }}>
          <Typography
            variant='caption'
            className={classes.staticLabel}
            style={{ color: disabled ? '#D0D0D0' : '#051923' }}
          >{staticLabel}
          </Typography>
          {tooltip &&
            <TooltipStyled
              title={tooltip}
              style={{ position: 'absolute', right: 0, top: -5 }}
            />
          }
        </Grid>
      }
      {names && names.map((n, key) => (
        <Grid container direction='row' key={key} alignItems='center' className={classes.root} style={style}>
          <Radio
            checked={selectedValue === n}
            onChange={handleChange}
            key={key}
            value={n}
            name={n}
            icon={<RadioEmpty color={color} />}
            checkedIcon={<RadioActive color={color} />}
            inputProps={{ 'aria-label': n }}
            style={{ paddingLeft: 5, paddingRight: 5 }}
            data-cy={datacy}
            disabled={editing}
          />
          {labels &&
            <Typography
              variant='caption'
              className={classes.label}
              style={{ fontWeight: selectedValue === n ? '700' : '400' }}
            >{n}
            </Typography>
          }
        </Grid>
      ))}
    </>
  );
};

export default RadioStyled;
