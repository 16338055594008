import { createSlice } from '@reduxjs/toolkit';

export const domainsSlice = createSlice({
  name: 'domains',
  initialState: { value: [], isRetrieved: false },
  reducers: {
    setDomains: (state, action) => {
      state.value = action.payload;
      state.isRetrieved = true;
    },
    clearDomains: (state) => {
      state.value = null;
    },
    setRetrieved: (state, action) => {
      state.isRetrieved = action.payload;
    },
  },
});

export const { setDomains, clearDomains, setRetrieved } = domainsSlice.actions;

export const selectDomains = (state) => state.domains;

export default domainsSlice.reducer;