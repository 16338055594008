const creativeSize = [
    { label: "160x600 (wide skyscraper)", width: 160, height: 600 },
    { label: "120x600 (skyscraper)", width: 120, height: 600 },
    { label: "300x250 (medium rectangle)", width: 300, height: 250 },
    { label: "728x90 (leaderboard)", width: 728, height: 90 },
    { label: "300x600 (half-page ad)", width: 300, height: 600 },
    { label: "970x250 (billboard)", width: 970, height: 250 },
    { label: "468x60 (full banner)", width: 468, height: 60 },
    { label: "320x50 (mobile leaderboard)", width: 320, height: 50 },
    { label: "300x50 (mobile banner)", width: 300, height: 50 },
    { label: "320x480 (portrait interstitial)", width: 320, height: 480 },
    { label: "480x320 (landscape interstitial)", width: 480, height: 320 },
    { label: "320x100 (mobile large banner)", width: 320, height: 100 }
]

export default creativeSize;

