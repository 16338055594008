import { createSlice } from '@reduxjs/toolkit';

export const universalPixelsSlice = createSlice({
  name: 'universalPixels',
  initialState: { value: [], isRetrieved: false },
  reducers: {
    setUniversalPixels: (state, action) => {
      state.value = action.payload;
      state.isRetrieved = true;
    },
    clearUniversalPixels: (state) => {
      state.value = null;
    },
    setRetrieved: (state, action) => {
      state.isRetrieved = action.payload;
    },
  },
});

export const { setUniversalPixels, clearUniversalPixels, setRetrieved } = universalPixelsSlice.actions;

export const selectUniversalPixels = (state) => state.universalPixels;

export default universalPixelsSlice.reducer;