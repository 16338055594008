import React, { useState } from 'react';
import { useStyles } from './styles';
import { Grid, TextField } from '@material-ui/core';
import ButtonContained from 'components/elements/ButtonContained';
import TextFieldStyled from 'components/elements/TextFieldStyled';
import CreativesCreate from 'containers/CreativesCreate';
import BackButtonOutlined from 'components/elements/BackButtonOutlined';
import { useGetSelectedAdvertiser } from 'utils/hooks/advertiserHooks';
import creativeVastTag from 'utils/models/creativeVastTag';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import { useEffect } from 'react';
import { trackEvent } from 'utils/hooks/analyticsG4';
import sendSlackMessage from 'utils/functions/sendSlackMessage';
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';
import * as Sentry from "@sentry/react";

const VideoTagCreate = () => {
  const classes = useStyles();
  const [activeCreative, setActiveCreative] = useState(creativeVastTag);
  const { selectedAdvertiser } = useGetSelectedAdvertiser();
  const history = useHistory();
  const [videoAttribute, setVideoAttribute] = useState(creativeVastTag.video_attribute);
  const [isUploading, setIsUploading] = useState(false);


  useHubSpotHook('/creatives/create/video');

  useEffect(() => {
    setActiveCreative([]);
  }, [])

  const handleUpload = async () => {
    setIsUploading(true);

    const toastId = toast('Your creatives are being uploaded...', {
      progressClassName: 'toastProgressHidden'
    });

    const newActiveCreative = {
      ...activeCreative,
      advertiser_id: selectedAdvertiser.id,
      media_url: activeCreative.media_url,
      media_url_secure: activeCreative.media_url,
      video_attribute: {
        ...videoAttribute,
        wrapper: {
          url: activeCreative.media_url,
          secure_url: activeCreative.media_url,
          elements: [
            {
              vast_element_type_id: 1,
              type: "linear",
              trackers: null
            }
          ]
        },
      },
      template: { id: 6439 },
      name: activeCreative.tag_name,
      adchoices: {
        adchoices_enabled: true
      },
    }


    console.log('newActiveCreative:', newActiveCreative);

    axiosClient.post(
      `/creative-vast`,
      newActiveCreative
    ).then(createdCreative => {
      // console.log('createdCreative:', createdCreative);
      setIsUploading(false);
      toast.dismiss(toastId);
      toast.success('Your creatives have been uploaded!', {
        progressClassName: 'toastProgressSuccess'
      });
      trackEvent({
        category: 'Snippet',
        action: 'select_item',
        label: "app.adpersonam.io"
      });
      sendSlackMessage(`:white_check_mark: A new Vast Tag has been uploaded id: ${createdCreative.data.id}`)
      history.push('/snippets');
    })
      .catch(err => {
        setIsUploading(false);
        const responseData = err.response.data.message;
        Sentry.captureException(err);
        toast.dismiss(toastId);
        toast.error(responseData, {
          progressClassName: 'toastProgressError'
        });
      });
  }


  const updateFormText = (field, e) => {
    const value = e.target.value;
    // Update the activeCreative state with the new value
    setActiveCreative({
      ...activeCreative,
      [field]: value
    });
  }

  return (
    <CreativesCreate creativeType="vastTag">
      <Grid container direction='column' className={classes.root}>

        <TextFieldStyled
          staticLabel="Enter name of the creative"
          placeholder="Creative Name"
          //sublabel="The landing page must match the language, brand, and offer of the creative"
          style={{ marginTop: 20, marginBottom: 20 }}
          handleUpdate={(e) => updateFormText('tag_name', e)}
          field="tag_name"
          disabled={isUploading}
          datacy="input-banner-click-url"
        />
        <TextField
          id="filled-textarea"
          multiline
          minRows={5}
          variant="outlined"
          placeholder={"The URL of the secure (HTTPS) creative - can be flash, HTML, javascript to be served on a secure ad call. URL must exist and should point to a CDN hosted VAST XML file."}
          field="media_url"
          style={{ marginTop: 10 }}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          onChange={(e) => updateFormText('media_url', e)}
        />


        <Grid container className={classes.buttons}>
          <BackButtonOutlined
            label="Back"
          />
          <ButtonContained
            label="Upload"
            style={{ marginLeft: 10 }}
            onClick={handleUpload}
            disabled={isUploading}
            datacy="button-submit-video"
          />
        </Grid>
      </Grid>
    </CreativesCreate>
  );
};

export default VideoTagCreate;
