import { createSlice } from '@reduxjs/toolkit';

export const pixelsSlice = createSlice({
  name: 'pixels',
  initialState: { value: [], isRetrieved: false },
  reducers: {
    setPixels: (state, action) => {
      state.value = action.payload;
      state.isRetrieved = true;
    },
    clearPixels: (state) => {
      state.value = null;
    },
    setRetrieved: (state, action) => {
      state.isRetrieved = action.payload;
    },
  },
});

export const { setPixels, clearPixels, setRetrieved } = pixelsSlice.actions;

export const selectPixels = (state) => state.pixels;

export default pixelsSlice.reducer;