import ReactGA from "react-ga4";

// Initialize GA4
export const initGA = () => {
    ReactGA.initialize("G-NDW1F65KQ6");
};

// Track a custom event
export const trackEvent = ({ category, action, label, value, nonInteraction, transport }) => {
    //console.log('Sending event to GA4:', { category, action, label, value });
    ReactGA.event({
        category,
        action,
        label,
        value,
        nonInteraction,
        transport,
    });
};

export const trackPageview = (path, title) => {
    //console.log('Sending event to GA4:', { path, title });
    ReactGA.send({
        hitType: "pageview",
        page: path,
        title: title
    });
};

