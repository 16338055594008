import React, {useEffect, useState} from 'react';
import { useStyles } from './styles';
import { Dialog, DialogTitle, Grid, Paper, Typography } from '@material-ui/core';
import Close from 'icons/ui/Close';
import ButtonOutlined from '../ButtonOutlined';
import Draggable from 'react-draggable';
import ButtonContained from '../ButtonContained';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const DraggableConfirm = ({
  title,
  text,
  body,
  handleCancel,
  handleConfirm,
  confirmLabel,
  openState,
  datacy,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openState);

  useEffect(() => {
    setOpen(openState);
  },[openState])

  const stageClose = () => {
    setOpen(false);
    handleCancel();
  }

  const stageConfirm = () => {
    setOpen(false);
    handleConfirm();
  }

  return (
    <Dialog
      open={open}
      onClose={stageClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <Grid container direction='column' className={classes.root}>
        <Close className={classes.closeIcon} onClick={stageClose} />
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className={classes.dialogTitle}>
          <Typography className={classes.title}>{title}</Typography>
        </DialogTitle>
        <DialogTitle className={classes.dialogText}>
          <Typography className={classes.text}>{text}</Typography>
        </DialogTitle>
        {body}
        <Grid container direction='row' className={classes.actionCont}>
          <ButtonContained 
            label="Cancel"
            onClick={stageClose}  
          />
          <ButtonOutlined
            label={confirmLabel ? confirmLabel : "Confirm"} 
            style={{marginLeft: 10}}
            onClick={stageConfirm}
            datacy={datacy}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DraggableConfirm;
