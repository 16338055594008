import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    paddingTop: 60,
    [theme.breakpoints.down("md")]: {
      paddingTop: 58,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 62,
    },
    width: '100%',
    background: '#FAFAFA',
    // minHeight: '100vh'
  },
}));