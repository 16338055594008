import React from "react";
import { Route, withRouter } from "react-router-dom";

const PublicRoute = ({ component: Component, history, displayName, ...rest }) => {
  
const token = localStorage['access-token'];
const userData = localStorage['user-data'];

if(userData && token && window.location.pathname.includes('/login'))
{
  history.push('/');
}

  if(displayName) {
    document.title = 'ad:personam - '+displayName;
  }

  return (
  <Route
      {...rest}
      render={props => {
          return <Component {...props} />
      }
      }
    />  

)};

export default withRouter(PublicRoute);
