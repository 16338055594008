const creative3rdParty = {
    type: "banner",
    /*     template: {
            id: 6             //1:HTML Url 2:JS Url 5:Js Code 6:HTML Code
        }, */
    segments: null,
    allow_audit: "false",  //default
    is_self_audited: "false",
    audit_status: "no_audit", //default
    track_clicks: true,
    adchoices: {
        adchoices_enabled: true
    }
}

export default creative3rdParty;
