/* This template is used if your creative is a single URL that points to a piece of html code.
You will need to pass the URL in the media_url field and set an id of 1 in the template object. */

export const iframeTemplate = (width, height, mediaUrl) => `
    <iframe frameborder="0" width="${width}" height="${height}" marginheight="0" marginwidth="0" target="_blank" scrolling="no" src="${mediaUrl}"></iframe>
`;

/* This template is used if your creative is a single URL that points to a piece of Javascript code.
You will need to pass the URL in the media_url field and set an id of 2 in the template object. */

export const jsTemplate = (mediaUrl) => `
<html>
    <body style="margin-left: 0%; margin-right: 0%; margin-top: 0%; margin-bottom: 0%">
        <script type="text/javascript" src="${mediaUrl}"></script>
    </body>
</html>
`;


