import React, { useState, useEffect, useCallback } from 'react';
import { useStyles } from './styles';
import { Grid, Typography, Box, IconButton, CircularProgress } from '@material-ui/core';
import { useGetOwnUser } from 'utils/hooks/userHooks';
import { trackEvent } from 'utils/hooks/analyticsG4';
import LeftSidebarAssistant from 'components/navigation/LeftSidebarAssistant';
import axiosClient from "../../../utils/axiosClient";
import domainsQueryCat from 'utils/consts/domainsQueryCat';
import domainsQueryCountries from 'utils/consts/domainsQueryCountries';
import domainsQueryFilter from 'utils/functions/domainsQueryFilter';
import SelectStyled from 'components/elements/SelectStyled';
import ArrowDown from 'icons/ui/ArrowDown';
import ArrowUp from 'icons/ui/ArrowUp';
import Download from 'icons/ui/Download';
import CopyAll from 'icons/ui/CopyAll';
import Tooltip from '@material-ui/core/Tooltip';
import SparkAi from 'icons/ui/SparkAi';
import * as Sentry from "@sentry/react";
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';


const DomainsQuery = () => {
    const classes = useStyles();
    const { userData } = useGetOwnUser();
    const [hasPlan, setHasPlan] = useState(false);
    const [domainData, setDomainData] = useState([]);
    const [queryInitiated, setQueryInitiated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedParameters, setSelectedParameters] = useState({ category_name: '', country_name: '', media_type: '' });
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [showAll, setShowAll] = useState(false);

    useHubSpotHook('/domains-query');

    useEffect(() => {
        if (userData && userData["custom:subscription"]) {
            setHasPlan(true);
        } else {
            setHasPlan(false);
        }
    }, [userData])

    const updateFormSelection = (field, event) => {
        // Assuming event is an object with a target property holding the value
        const value = event.target ? event.target.value : event; // Fallback to event itself if it's directly the value
        setSelectedParameters(prev => {
            const updatedParams = { ...prev, [field]: value };
            // Check if all parameters are set
            const allParamsSet = Object.values(updatedParams).every(val => val !== '');
            setSubmitDisabled(!allParamsSet);
            return updatedParams;
        });
    };

    const handleSubmission = async () => {
        setIsLoading(true);
        setQueryInitiated(true); // If you're tracking fetch initiation
        try {
            const { category_name, country_name, media_type } = selectedParameters;
            const encodedCategoryName = encodeURIComponent(category_name);
            const encodedCountryName = encodeURIComponent(country_name);
            const encodedMediaType = encodeURIComponent(media_type);

            const response = await axiosClient.get(`/ask/domain?category_name=${encodedCategoryName}&country_name=${encodedCountryName}&media_type=${encodedMediaType}`);

            const lines = response.data.trim().split('\n');
            const domainsArray = lines.slice(1).map(line => line.split('\t')[0]); // Extract just the domain

            // Apply country-specific filtering
            const filteredDomains = domainsQueryFilter(domainsArray, country_name);

            setDomainData(filteredDomains);
            // Track success event
            trackEvent({
                category: 'AI',
                action: 'Domains Query',
                label: "app.adpersonam.io"
            });
        } catch (err) {
            console.error('There was a problem fetching the domains', err);
            Sentry.captureException(err);
        } finally {
            setIsLoading(false);
        }
    };




    const downloadCSV = () => {
        // Directly join domainData without adding a header
        let csvContent = "data:text/csv;charset=utf-8," + domainData.join("\n");
        const encodedUri = encodeURI(csvContent);

        // Sanitize the parameters to create a more suitable filename
        const sanitizeForFilename = (str) => str.replace(/&/g, 'and').replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '');

        const fileName = `${sanitizeForFilename(selectedParameters.category_name)}_${sanitizeForFilename(selectedParameters.country_name)}_${sanitizeForFilename(selectedParameters.media_type)}.csv`;

        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    const copyToClipboard = useCallback(() => {
        if (domainData && domainData.length > 0) {
            navigator.clipboard.writeText(domainData)
                .then(() => {
                    //console.log('Text copied to clipboard');
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err);
                });
        }
    }, [domainData]);


    return (
        <Grid container direction="column" className={classes.root}>
            {/* Header and Subtitle */}
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.header}>
                    Domain List Generator
                </Typography>
                <Box mb={2}>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                        Leverage the ad:personam domain-query service to seamlessly generate comprehensive domain lists tailored for your marketing initiatives.By querying our extensive dataset filtered by country, media type, and site category, you gain access to a specialized tool designed to enhance your campaign targeting.
                    </Typography>
                </Box>
            </Grid>



            {/* Main Content Grid with explicit spacing */}
            <Grid container item spacing={3}>

                {/* Left Sidebar */}
                <Grid item xs={12} md={3} style={{ marginTop: 40 }}>
                    <LeftSidebarAssistant />
                </Grid>

                {/* Middle Section for Dropdowns */}
                <Grid item xs={12} md={4} style={{ marginTop: 20 }}>
                    <SelectStyled
                        staticLabel="Select Category"
                        style={{ marginTop: 20, width: '100%' }}
                        options={domainsQueryCat}
                        handleUpdate={(e) => updateFormSelection('category_name', e)}
                        field="category_name"
                        noNone
                        datacy="select-category"
                    />
                    <SelectStyled
                        staticLabel="Select Country"
                        style={{ marginTop: 20, width: '100%' }}
                        options={domainsQueryCountries}
                        handleUpdate={(e) => updateFormSelection('country_name', e)}
                        field="country_name"
                        noNone
                        datacy="select-country"
                    />
                    <SelectStyled
                        staticLabel="Select Media Type"
                        style={{ marginTop: 20, width: '100%' }}
                        options={[
                            { label: 'Banner', value: 'banner' },
                            { label: 'Video', value: 'video' }
                        ]}
                        handleUpdate={(e) => updateFormSelection('media_type', e)}
                        field="media_type"
                        noNone
                        datacy="select-media-type"
                    />

                    <div>
                        <IconButton
                            className={classes.iconButton}
                            style={{ marginTop: 20 }}
                            onClick={handleSubmission}
                            disabled={!hasPlan || submitDisabled}
                        >
                            <SparkAi />
                            <Typography variant="body1" style={{ marginLeft: '20px' }}>Generate List</Typography>
                        </IconButton>
                        <Typography className={classes.text}>
                            The dynamically generated list of domains is provided based on current delivery within the selected categories, countries, and media types. Once you've retrieved the domains, you have the option to export this list. The exported CSV file allows for easy review and curation. We recommend carefully curating the list to ensure it aligns with your campaign goals and strategies. This process might involve removing any URLs that do not meet your targeting requirements or are deemed unsuitable for your audience.
                        </Typography>

                        {!hasPlan && (
                            <Typography variant="body2" color="error" style={{ marginTop: 8 }}>
                                You must have an active subscription to use this service.
                            </Typography>
                        )}

                    </div>
                </Grid>

                {/* Right Section for Results */}
                <Grid item xs={12} md={4}>

                    {queryInitiated && (
                        isLoading ? (
                            <CircularProgress style={{ marginTop: 40, marginLeft: 30 }} />
                        ) : (
                            <>
                                <Grid className={classes.reportCont}>
                                    <div >
                                        <Tooltip title="Export" placement="right" arrow>
                                            <span>
                                                <Download
                                                    className={domainData && domainData.length > 0 ? classes.icon : classes.disabledIcon}
                                                    onClick={downloadCSV}

                                                />
                                            </span>
                                        </Tooltip>

                                        <Tooltip title="Copy" placement="right" arrow>
                                            <span>
                                                <CopyAll
                                                    className={domainData && domainData.length > 0 ? classes.icon : classes.disabledIcon}
                                                    onClick={() => copyToClipboard()}
                                                    style={{ marginRight: 20 }}
                                                />
                                            </span>
                                        </Tooltip>
                                    </div>

                                    {domainData && domainData.length > 0 ? (
                                        domainData.slice(0, showAll ? 100 : 20).map((domain, index) => (
                                            <Typography className={classes.urlFormat} key={index}>{domain}</Typography>
                                        ))
                                    ) : (
                                        <Typography className={classes.urlFormat}>No data available</Typography>
                                    )}
                                    {domainData && domainData.length > 20 && (
                                        <Grid container onClick={() => setShowAll(!showAll)}
                                            className={classes.showMoreCont}>
                                            <Typography className={classes.textShow}>{showAll ? 'Show less' : 'Show more'}</Typography>
                                            {showAll ? <ArrowUp className={classes.arrow} /> : <ArrowDown className={classes.arrow} />}
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        )
                    )}
                </Grid>

            </Grid>
        </Grid>



    );
};

export default DomainsQuery;




