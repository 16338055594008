import { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { FormControl, TextField, Typography, Grid } from '@material-ui/core';
import TooltipStyled from '../TooltipStyled';

export default function TextFieldStyled({
  initial,
  style,
  disabled,
  staticLabel,
  placeholder,
  field,
  handleUpdate,
  type,
  pattern,
  required,
  InputProps,
  sublabel,
  autoComplete,
  tooltip,
  max,
  min,
  step,
  datacy,
  error,
  helperText,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(initial ? initial : "");

  useEffect(() => {
    if (initial) {
      setValue(initial);
    }
  }, [initial])

  const handleChange = (e) => {
    setValue(e.target.value);
    handleUpdate && handleUpdate(e);
  };

  return (
    <Grid container direction='column' style={{ width: 'auto', ...style }}>
      {staticLabel &&
        <Grid container direction='row' style={{ position: 'relative' }}>
          <Typography
            variant='caption'
            className={classes.staticLabel}
            style={{ color: disabled ? '#D0D0D0' : '#051923' }}
          >{staticLabel}
          </Typography>
          {tooltip &&
            <TooltipStyled
              title={tooltip}
              style={{ position: 'absolute', right: 0, top: -5 }}
            />
          }
        </Grid>
      }
      <FormControl className={classes.margin}>
        <TextField
          id={field}
          value={value}
          variant='outlined'
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
          className={classes.selectRoot}
          margin="dense"
          name={field}
          type={type}
          pattern={pattern}
          required={required}
          autoComplete={autoComplete}
          InputProps={{ className: classes.input, inputProps: { min: min ? min : 0, max: max ? max : null, step: step ? step : 1 } }}
          data-cy={datacy}
          error={error}
          helperText={helperText}
        />
      </FormControl>
      {sublabel &&
        <Typography
          variant='caption'
          className={classes.subLabel}
        >{sublabel}
        </Typography>
      }
    </Grid>
  );
}