import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        background: '#fff',
        padding: '32px 70px 32px 0px',
        [theme.breakpoints.down("sm")]: {
            padding: '32px 31px 32px 31px',
        },
    },
    title: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 24,
        lineHeight: '36px',
    },
    subtitle: {
        fontFamily: 'Open Sans',
        fontWeight: 700,
        fontSize: 12,
        lineHeight: '16.34px',
        marginTop: 20,
        marginBottom: 10,
    },
    buttons: {
        marginTop: 20,
    },
    uploadingText: {
        marginTop: 20,
    },
    input: {
        '& .MuiOutlinedInput-input': {
            paddingTop: 10.5,
            paddingBottom: 10.5,
        },
        background: '#fff',
    },
    staticLabel: {
        fontSize: 12,
        fontFamily: 'Open Sans',
        lineHeight: "16.34px",
        fontWeight: 700,
        marginBottom: 0,
        marginLeft: 1,
        color: '#051923'
    }
}));
