import React, { useState } from 'react';
import { useStyles } from './styles';
import { CircularProgress, Grid, Hidden, Typography } from '@material-ui/core';
import ButtonContained from 'components/elements/ButtonContained';
import Haken from 'icons/ui/Haken';
import { useEffect } from 'react';
import axiosClient from 'utils/axiosClient';
import subscriptions from 'utils/consts/subscriptions';
import WindowFocusHandler from 'components/utils/WindowFocusHandler';
import { useGetOwnUser } from 'utils/hooks/userHooks';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { trackEvent } from 'utils/hooks/analyticsG4';
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';
import * as Sentry from "@sentry/react";

const PricingTable = props => {
  const classes = useStyles();
  const [plans, setPlans] = useState([]);
  const [plan] = useState(subscriptions['top']);
  const [submitting, setSubmitting] = useState(false);
  const [waitingForFocus, setWaitingForFocus] = useState(false);
  const { userData } = useGetOwnUser();
  const history = useHistory();

  useHubSpotHook('/pricing');

  useEffect(() => {
    // Track the event when the Subscribe page mounts
    trackEvent({
      category: 'Subscriptions',
      action: 'begin_checkout',
      label: "app.adpersonam.io"
    });
  }, []);

  const handleOnFocus = () => {
    if (waitingForFocus) {
      //console.log('handling on focus');
      setWaitingForFocus(false);
      redirectToProcessing(true);
    }
  }

  useEffect(() => {
    axiosClient.get(
      `/checkout/plans`
    ).then(gotPlans => {
      //console.log('Got Plans:', gotPlans.data);
      if (gotPlans.data) {
        setPlans(gotPlans.data);
      }
    })
      .catch(err => {
        console.log('Err Getting Stripe Plans:', err.response);
      });
  }, [])

  const redirectToProcessing = (success) => {
    history.push('/processing?type=subscription&success=' + success)
  }

  const selectPlan = (selectedPlan) => {
    setSubmitting(true);
    const chosenPlan = plans.filter(p => p.name.toUpperCase() === selectedPlan);
    trackEvent({
      category: 'Subscriptions',
      action: 'add_to_cart',
      label: "app.adpersonam.io"
    });

    if ((userData["custom:subscription"] && userData["custom:subscription"].toUpperCase()) === subscriptions['top']
      || (userData["custom:subscription"] && userData["custom:subscription"].toUpperCase()) === subscriptions['bottom']) {
      axiosClient.put(
        `/checkout/subscribe?price_id=` + chosenPlan[0].id
      ).then(gotSubscription => {
        // console.log('gotSubscription from PUT:', gotSubscription);
        if (gotSubscription.data && gotSubscription.data === "success") {
          redirectToProcessing(true);
        } else {
          setWaitingForFocus(true);
          window.open(gotSubscription.data, '_blank');
        }
      })
        .catch(err => {
          console.log('Err Putting Subscription:', err.response);
          toast.error(err.response.data.message, {
            progressClassName: 'toastProgressError'
          });
          Sentry.captureException(err.response.data);
          setSubmitting(false);
        });
    } else {
      axiosClient.post(
        `/checkout/subscribe?price_id=` + chosenPlan[0].id
      ).then(gotSubscription => {
        if (gotSubscription.data) {
          window.open(gotSubscription.data, "_self")
        }
      })
        .catch(err => {
          console.log('Err Posting Subscription:', err.response);
          setSubmitting(false);
        });
    }

  }

  return (
    <Grid container direction='column' alignItems='center' className={classes.root}>

      <Typography variant='h1' className={classes.title}>Transparent Pricing</Typography>
      <Typography variant='h6' className={classes.subtitle}>Pricing built for people just like you.</Typography>

      <Grid container direction='column' className={classes.mainCont}>
        <Grid container direction='row' justifyContent='flex-end' className={classes.cardsCont}>

          <Grid item md={4} xs={12}>
            <Grid container direction='column' alignItems='center'
              className={plan === subscriptions['bottom'] ? classes.planCardSelected : classes.planCard}>
              <Typography className={plan === subscriptions['bottom'] ? classes.planTypeSelected : classes.planType}>Starter</Typography>
              <Typography className={plan === subscriptions['bottom'] ? classes.planPriceSelected : classes.planPrice}>€149</Typography>
              <Typography className={plan === subscriptions['bottom'] ? classes.planPeriodSelected : classes.planPeriod}>per month<br />(plus VAT)</Typography>
              <ButtonContained
                label={submitting
                  ? <CircularProgress size={20} />
                  : userData && userData["custom:subscription"] && userData["custom:subscription"].toUpperCase() === subscriptions['bottom']
                    ? "Subscribed"
                    : "Choose Plan"
                }
                style={{
                  marginTop: 42,
                  background: plan === subscriptions['bottom'] ? '#FFF' : '#F9F2FF',
                  color: plan === subscriptions['bottom'] ? '#09A1F9' : '#4E2D92'
                }}
                onClick={() => selectPlan(subscriptions['bottom'])}
                disabled={userData && userData["custom:subscription"] && userData["custom:subscription"].toUpperCase() === subscriptions['bottom']}
              />
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <Grid container direction='column' alignItems='center'
              className={plan === subscriptions['top'] ? classes.planCardSelected : classes.planCard}>
              <Typography className={plan === subscriptions['top'] ? classes.planTypeSelected : classes.planType}>Pro</Typography>
              <Typography className={plan === subscriptions['top'] ? classes.planPriceSelected : classes.planPrice}>€299</Typography>
              <Typography className={plan === subscriptions['top'] ? classes.planPeriodSelected : classes.planPeriod}>per month<br />(plus VAT)</Typography>
              <ButtonContained
                label={submitting
                  ? <CircularProgress size={20} />
                  : userData && userData["custom:subscription"] && userData["custom:subscription"].toUpperCase() === subscriptions['top']
                    ? "Subscribed"
                    : "Choose Plan"
                }
                style={{
                  marginTop: 42,
                  background: plan === subscriptions['top'] ? '#FFF' : '#F9F2FF',
                  color: plan === subscriptions['top'] ? '#09A1F9' : '#4E2D92'
                }}
                onClick={() => selectPlan(subscriptions['top'])}
                disabled={userData && userData["custom:subscription"] && userData["custom:subscription"].toUpperCase() === subscriptions['top']}
              />
            </Grid>
          </Grid>

        </Grid>

        <Grid container direction='column' className={classes.rowsCont}>
          <Hidden smUp>
            <Grid container direction='row' className={classes.whiteRow}>
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={4} className={classes.iconCont}>
                <Typography className={classes.rowText}>Starter</Typography>
              </Grid>
              <Grid item xs={4} className={classes.iconCont}>
                <Typography className={classes.rowText}>Pro</Typography>
              </Grid>
            </Grid>
          </Hidden>
          <Grid container direction='row' className={classes.greyRow}>
            <Grid item xs={4}>
              <Typography className={classes.rowText}>Contextual</Typography>
            </Grid>
            <Grid item xs={4} className={classes.iconCont}>
              <Haken className={classes.icon} />
            </Grid>
            <Grid item xs={4} className={classes.iconCont}>
              <Haken className={classes.icon} />
            </Grid>
          </Grid>
          <Grid container direction='row' className={classes.whiteRow}>
            <Grid item xs={4}>
              <Typography className={classes.rowText}>Audience</Typography>
            </Grid>
            <Grid item xs={4} className={classes.iconCont}>
              <Haken className={classes.icon} />
            </Grid>
            <Grid item xs={4} className={classes.iconCont}>
              <Haken className={classes.icon} />
            </Grid>
          </Grid>
          <Grid container direction='row' className={classes.greyRow}>
            <Grid item xs={4}>
              <Typography className={classes.rowText}>Retargeting</Typography>
            </Grid>
            <Grid item xs={4} className={classes.iconCont}>
              <Haken className={classes.icon} />
            </Grid>
            <Grid item xs={4} className={classes.iconCont}>
              <Haken className={classes.icon} />
            </Grid>
          </Grid>
          <Grid container direction='row' className={classes.whiteRow}>
            <Grid item xs={4}>
              <Typography className={classes.rowText}>Macro (Weather)</Typography>
            </Grid>
            <Grid item xs={4} className={classes.iconCont}>

            </Grid>
            <Grid item xs={4} className={classes.iconCont}>
              <Haken className={classes.icon} />
            </Grid>
          </Grid>
          <Grid container direction='row' className={classes.greyRow}>
            <Grid item xs={4}>
              <Typography className={classes.rowText}>Video & Audio</Typography>
            </Grid>
            <Grid item xs={4} className={classes.iconCont}>

            </Grid>
            <Grid item xs={4} className={classes.iconCont}>
              <Haken className={classes.icon} />
            </Grid>
          </Grid>
        </Grid>

      </Grid>


      <WindowFocusHandler
        handleOnFocus={handleOnFocus}
      />
    </Grid>
  );
};

export default PricingTable;
