import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: 42,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  logoImg: {
    width: 210
  },
  mainCont: {
    marginBottom: 20, 
    marginTop: 60,
    background: '#fff',
    padding: 20,
    borderRadius: 8,
    width: 420,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      marginTop: 0,
      width: '100%',
      paddingTop: '10%',
      paddingBottom: '90%'
    },
  },
  title: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    paddingRight: 100,
  },
  signIn: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
    marginTop: 20,
  },
  termsLink: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
    position: 'relative',
    right: 4,
  },
  forgotLink: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
    textAlign: 'right'
  },
  subCont: {
    position: 'relative',
    bottom: 4,
  },
  errorCont: {
    background: '#FFE4E6',
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
  },
  errorIcon: {
    color: '#E11D48',
    width: 20,
    marginLeft: 10,
    marginRight: 10,
    position: 'relative',
    top: 1,
  },
  errorText: {
    color: '#E11D48',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
  }
}));
