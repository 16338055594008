import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import logo from 'images/logos/logo_circle_primary_main.png';
import NavLink from 'components/navigation/NavLink';
import Homepage from 'icons/ui/Homepage';
import Logout1 from 'icons/ui/Logout1';
import Help from 'icons/ui/Help';
import User from 'icons/ui/User';
import Amplify, { Auth } from 'aws-amplify';
import { clearUser } from 'redux/slices/user';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSelectedAdvertiser } from 'utils/hooks/advertiserHooks';
import SubTopBar from '../SubTopBar';
import subBarSnippetsRoutes from 'utils/consts/subBarSnippetsRoutes';
import subBarCampaignsRoutes from 'utils/consts/subBarCampaignsRoutes';
import { selectAdvertisers } from 'redux/slices/advertisers';
import config from "../../../aws-exports-with-auth";
import * as Sentry from "@sentry/react";


const TopBar = props => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedAdvertiser } = useGetSelectedAdvertiser();
  const { value, isRetrieved } = useSelector(selectAdvertisers);
  const [localAdvertiser, setLocalAdvertiser] = useState();

  useEffect(() => {
    if (value) {
      setLocalAdvertiser(value)
    }

  }, [value])

  const handleLoginLogout = () => {
    if (localStorage.length > 0) {
      Auth.signOut().then(res => {
        dispatch(clearUser());
        const configUpdate = config;
        configUpdate.aws_user_pools_web_client_id = '23t8kstfos4ko5dt3jgkjp4r3s';
        Amplify.configure(configUpdate);
        history.push('/login');
      }).catch(err => {
        console.log(err)
        Sentry.captureException(err);
      });
    }
  }

  const splitPathname = history.location.pathname.split('/');
  const truncatedPathname = (splitPathname[1] && splitPathname[2])
    ? "/" + splitPathname[1] + "/" + splitPathname[2]
    : "";

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.barRoot}>
        <Container maxWidth="xl">
          <Toolbar disableGutters className={classes.toolbar}>
            <NavLink path='/'>
              <img src={logo} alt='' className={classes.logo} />
            </NavLink>

            {/* Links and icons */}
            <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <NavLink path='/'>
                <IconButton className={classes.icon}>
                  <Homepage />
                </IconButton>
              </NavLink>


              <NavLink path='/profile'>
                <IconButton className={classes.icon}>
                  <User />
                </IconButton>
              </NavLink>

              {/* External Link to Help */}
              <a href='https://adpersonam.tawk.help/' target='_blank' rel='noopener noreferrer'>
                <IconButton className={classes.icon}>
                  <Help />
                </IconButton>
              </a>

              <IconButton onClick={handleLoginLogout} className={classes.icon}>
                <Logout1 className={classes.logoutIcon} />
              </IconButton>
            </div>



            {/* Other existing elements can go here... */}

          </Toolbar>
        </Container>
      </AppBar>

      {
        localAdvertiser &&
        (subBarSnippetsRoutes.includes(history.location.pathname) ||
          subBarCampaignsRoutes.includes(history.location.pathname) ||
          subBarCampaignsRoutes.includes(truncatedPathname)) &&
        <SubTopBar />
      }

    </div>
  );
};

export default TopBar;









