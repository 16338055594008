import { createSlice } from '@reduxjs/toolkit';

export const advertisersSlice = createSlice({
  name: 'advertisers',
  initialState: { value: [], isRetrieved: false },
  reducers: {
    setAdvertisers: (state, action) => {
      state.value = action.payload;
      state.isRetrieved = true;
    },
    clearAdvertisers: (state) => {
      state.value = null;
    },
    setRetrieved: (state, action) => {
      state.isRetrieved = action.payload;
    },
  },
});

export const { setAdvertisers, clearAdvertisers, setRetrieved } = advertisersSlice.actions;

export const selectAdvertisers = (state) => state.advertisers;

export default advertisersSlice.reducer;