import { createSlice } from '@reduxjs/toolkit';

export const audiencesSlice = createSlice({
  name: 'audiences',
  initialState: { value: [], isRetrieved: false },
  reducers: {
    setAudiences: (state, action) => {
      state.value = action.payload;
      state.isRetrieved = true;
    },
    clearAudiences: (state) => {
      state.value = null;
    },
    setRetrieved: (state, action) => {
      state.isRetrieved = action.payload;
    },
  },
});

export const { setAudiences, clearAudiences, setRetrieved } = audiencesSlice.actions;

export const selectAudiences = (state) => state.audiences;

export default audiencesSlice.reducer;