import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  barRoot: {
    background: theme.palette.primary.main, // Updated to use the primary color
    boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.15)',
    height: 100,
  },
  toolbar: {

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hamburgerIcon: {
    color: theme.palette.grey.dark, // Updated to use light grey color
  },
  logo: {
    position: 'absolute',
    top: 10, // Updated to center the logo
    left: 20,
    width: 80, // Reduced the width
    height: 80, // Reduced the height
  },
  icon: { // Common styles for icons
    color: theme.palette.grey.light, // Updated to use light grey color
  },
  logoutIcon: {
    color: theme.palette.grey.light,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.grey.light
    },
    position: 'relative',
    // top: 2,
  },
}));
