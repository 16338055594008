import { useStyles } from './styles';
import {
  Typography,
} from '@material-ui/core';

export default function VideoTagHelpText() {
  const classes = useStyles();

  return (
    <div>
      <ul>
        <li><Typography className={classes.helpText}><b>Test your URL in a VAST validator</b> </Typography></li>
        <li><Typography className={classes.helpText}><b>Confirm that your URL is returning valid VAST XML content:</b> When placing the URL in a browser, it should return VAST compliant XML as per IAB Standards. If the response is empty, the URL may no longer be live. Contact your third-party hosting service to confirm.</Typography></li>
        <li><Typography className={classes.helpText}><b>Correct URL but creative is not displaying/displaying incorrectly:</b> If an ad is returned by the URL in a validator, but the creative is not displaying it or is failing audit for improper display, ensure that your third-party hosting service is not blocking the adnxs.com or adnxs.net domains or the US/NY geo</Typography></li>
      </ul>
    </div>
  );
}