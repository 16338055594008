import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: { value: {
    id: 1234,
    companyName: 'Cool Corp',
    license: false,
  }, isRetrieved: false },
  reducers: {
    setProfile: (state, action) => {
      state.value = action.payload;
      state.isRetrieved = true;
    },
    clearProfile: (state) => {
      state.value = null;
    },
    setRetrieved: (state, action) => {
      state.isRetrieved = action.payload;
    },
  },
});

export const { setProfile, clearProfile, setRetrieved } = profileSlice.actions;

export const selectProfile = (state) => state.profile;

export default profileSlice.reducer;