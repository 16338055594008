import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { Grid, Typography } from '@material-ui/core';
import ButtonContained from 'components/elements/ButtonContained';
import TextFieldStyled from 'components/elements/TextFieldStyled';
import AccordionStyled from 'components/elements/AccordionStyled';
import CreativesCreate from 'containers/CreativesCreate';
import BackButtonOutlined from 'components/elements/BackButtonOutlined';
import UploadButton from 'components/elements/UploadButton';
import { useGetSelectedAdvertiser } from 'utils/hooks/advertiserHooks';
import nativeCreative from 'utils/models/nativeCreative';
import FilenameCards from 'components/elements/FilenameCards';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import uploadCreativeFile from 'utils/functions/uploadCreativeFile';
import { trackEvent } from 'utils/hooks/analyticsG4';
import sendSlackMessage from 'utils/functions/sendSlackMessage';
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';
import * as Sentry from "@sentry/react";

const NativeCreate = () => {
  const classes = useStyles();
  const [activeCreative] = useState(nativeCreative);
  const [logos, setLogos] = useState([]);
  const [images, setImages] = useState([]);
  const [imageAssets, setImageAssets] = useState([]);
  const { selectedAdvertiser } = useGetSelectedAdvertiser();
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [dataAssets, setDataAssets] = useState([]);
  const [link, setLink] = useState({ url: '' });
  const [pixels, setPixels] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);

  useHubSpotHook('/creatives/create/native');

  useEffect(() => {
    setCanSubmit(false);
    if (logos.length > 0
      && images.length > 0
      && dataAssets.title
      && dataAssets.description
      && dataAssets.sponsored_by
      && dataAssets.call_to_action
      && link.url
    ) {
      setCanSubmit(true);
    }
  }, [logos, images, activeCreative, link])

  const handleAddLogo = async (file) => {
    setLogos([...logos, file]);
  }

  const handleAddImage = (file) => {
    setImages([...images, file]);
  }

  const handleUpload = async () => {
    setIsUploading(true);

    const toastId = toast('Your creatives are being uploaded...', {
      progressClassName: 'toastProgressHidden'
    });

    async function handleImages(files, type) {
      const newImageAssets = [...imageAssets];
      for (const file of files) {
        const uploadedFile = await uploadCreativeFile(file, "image");

        if (uploadedFile === 'File Size over 10MB') {
          toast.dismiss(toastId);
          toast.error('The file size is too large!', {
            progressClassName: 'toastProgressError'
          });
          Sentry.captureMessage("Something went wrong Type: Native");
          return null;
        } else if (!uploadedFile) {
          toast.dismiss(toastId);
          toast.error('There was a problem uploading your file!', {
            progressClassName: 'toastProgressError'
          });
          Sentry.captureMessage("There was a problem uploading your file! Type: Native");
          return null;
        }

        newImageAssets.push({
          image_type: type,
          media_asset_id: uploadedFile.data
        })
      }
      setImageAssets(newImageAssets);
      return newImageAssets;
    }
    const logosHandled = await handleImages(logos, "icon_image");
    const imagesHandled = await handleImages(images, "main_image");

    if (!logosHandled) {
      setIsUploading(false);
      setLogos([]);
      return;
    }

    if (!imagesHandled) {
      setIsUploading(false);
      setImages([]);
      return;
    }

    const image_assets = [...logosHandled, ...imagesHandled];

    const keys = Object.keys(dataAssets);
    const data_assets = keys.map(key => {
      return { data_type: key, value: dataAssets[key] };
    });

    const newActiveCreative = {
      ...activeCreative,
      advertiser_id: selectedAdvertiser.id,
      description: dataAssets.description,
      native_attribute: {
        link,
        data_assets,
        image_assets,
      },
      pixels,
      name: images[0].name,
    }

    axiosClient.post(
      `/creative`,
      newActiveCreative
    ).then(createdCreative => {
      setIsUploading(false);
      toast.dismiss(toastId);
      toast.success('Your creatives have been uploaded!', {
        progressClassName: 'toastProgressSuccess'
      });
      trackEvent({
        category: 'Snippet',
        action: 'select_item',
        label: "app.adpersonam.io"
      });
      sendSlackMessage(`:white_check_mark: A new native ad has been uploaded id: ${createdCreative.data.id}`)
      history.push('/snippets');
    })
      .catch(err => {
        setIsUploading(false);
        console.log(err.response);
        Sentry.captureException(err);
        toast.dismiss(toastId);
        toast.error('There was a problem uploading your creatives', {
          progressClassName: 'toastProgressError'
        });
        Sentry.captureMessage("There was a problem uploading your creatives");
      });
  }

  const updateFormText = (e) => {
    setDataAssets({
      ...dataAssets,
      [e.target.id]: e.target.value
    })
  }

  return (
    <CreativesCreate creativeType="native">
      <Grid container direction='column' className={classes.root}>
        <Typography variant='h2' className={classes.title}>Create Native</Typography>

        <Grid container alignItems='flex-end'>
          <Grid item md={5}>
            <UploadButton
              title="JPEG, PNG"
              subtitle="Max. file size 200KB"
              handleAddButton={handleAddLogo}
              label="Add Logo"
              style={{ marginTop: 20 }}
              datacy="button-upload-native-logo"
            />
          </Grid>
          <Grid item md={7}>
            <FilenameCards
              files={logos}
              handleNewFiles={setLogos}
            />
          </Grid>
        </Grid>

        <Grid container alignItems='flex-end'>
          <Grid item md={5}>
            <UploadButton
              title="JPEG, PNG"
              subtitle="Max. file size 1MB"
              handleAddButton={handleAddImage}
              label="Add Image"
              style={{ marginTop: 20 }}
              datacy="button-upload-native-image"
            />
          </Grid>
          <Grid item md={7}>
            <FilenameCards
              files={images}
              handleNewFiles={setImages}
            />
          </Grid>
        </Grid>

        <TextFieldStyled
          staticLabel="Title of the Ad"
          placeholder="Placeholder"
          style={{ marginTop: 20, width: '70%' }}
          handleUpdate={updateFormText}
          field="title"
          disabled={isUploading}
          datacy="input-native-title"
        />

        <TextFieldStyled
          staticLabel="Enter a Body Text"
          placeholder="Maximum 100 characters"
          sublabel="Enter a description that will appear on the native creative."
          style={{ marginTop: 20 }}
          handleUpdate={updateFormText}
          field="description"
          disabled={isUploading}
          datacy="input-native-description"
        />

        <TextFieldStyled
          staticLabel="Enter Sponsor"
          placeholder="Maximum 25 characters"
          sublabel="Enter your brand name/Sponsor"
          style={{ marginTop: 20 }}
          handleUpdate={updateFormText}
          field="sponsored_by"
          disabled={isUploading}
          datacy="input-native-sponsored"
        />

        <TextFieldStyled
          staticLabel="Enter Call to Action"
          placeholder="Maximum 25 characters"
          sublabel="Suggested Action for the next step"
          style={{ marginTop: 20 }}
          handleUpdate={updateFormText}
          field="call_to_action"
          disabled={isUploading}
          datacy="input-native-cta"
        />

        <TextFieldStyled
          staticLabel="Enter the URL users will be redirected to, when clicking the creative"
          placeholder="This value must begin with “http://” or “https://”"
          sublabel="The landing page must match the language, brand and offer of the creative."
          style={{ marginTop: 20 }}
          handleUpdate={(e) => setLink({ url: e.target.value })}
          field="link"
          disabled={isUploading}
          datacy="input-native-link"
        />

        <AccordionStyled
          title="Add third-party pixels (optional)"
          style={{ marginTop: 20 }}
        >
          <TextFieldStyled
            staticLabel="Add a third-party impression pixel"
            placeholder="an url-image pixel starting with https://"
            style={{ width: '100%' }}
            handleUpdate={(e) => setPixels([{ format: "url_image", "secure_url": e.target.value }])}
            field="pixels"
            disabled={isUploading}
          />
        </AccordionStyled>

        {isUploading ?
          <Typography variant='h6' className={classes.uploadingText}>Uploading...</Typography>
          :
          <Grid container className={classes.buttons}>
            <BackButtonOutlined
              label="Back"
            />
            <ButtonContained
              label="Upload"
              style={{ marginLeft: 10 }}
              onClick={handleUpload}
              disabled={!canSubmit}
              datacy="button-submit-native"
            />
          </Grid>
        }
      </Grid>
    </CreativesCreate>
  );
};

export default NativeCreate;
