const creativeVast = {
  name: "test_vide_demo_workspace_v3.mp4",  //filename
  media_assets: [
    {
      media_asset_id: 24252820 //this is returned when uploading the creative
    }
  ],
  click_url: "https://www.adpersonam.io/", //objectID : #clickUrl.value
  video_attribute: {
    duration_ms: 22000,  //The duration, in milliseconds, of the in-stream (VAST) video or audio creative. This must be greater than 0.
    inline: {
      ad_title: "test_vide_demo_workspace_v3.mp4", //filename
      linear: {
        trackers: null,
        skipoffset_seconds: null, //this is a default value 
      }
    }
  },
  template: {
    id: 6439             //Video creatives: 6439 Audio creatives: 38745
  },
  advertiser_id: 5190305,  //advertiserID
  segments: null,
  allow_audit: "false",  //default
  is_self_audited: "false", //default
  sla: 0
}

export default creativeVast;