import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { Grid, TextField, Typography } from '@material-ui/core';
import TextFieldStyled from 'components/elements/TextFieldStyled';
import SelectStyled from 'components/elements/SelectStyled';
import AccordionStyled from 'components/elements/AccordionStyled';
import ButtonContained from 'components/elements/ButtonContained';
import creativeHtml from 'utils/models/creativeHtml';
import creativeSize from "../../../utils/consts/creativeSize";
import { useGetSelectedAdvertiser } from 'utils/hooks/advertiserHooks';
import CreativesCreate from 'containers/CreativesCreate';
import BackButtonOutlined from 'components/elements/BackButtonOutlined';
import UploadButton from 'components/elements/UploadButton';
import FilenameCards from 'components/elements/FilenameCards';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import { trackEvent } from 'utils/hooks/analyticsG4';
import sendSlackMessage from 'utils/functions/sendSlackMessage';
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';
import uploadCreativeFile from "../../../utils/functions/uploadCreativeFile";
import * as Sentry from "@sentry/react";

const HtmlCreate = () => {
    const classes = useStyles();
    const [activeCreative, setActiveCreative] = useState(creativeHtml);
    const [files, setFiles] = useState([]);
    const [mediaAssets, setMediaAssets] = useState([]);
    const { selectedAdvertiser } = useGetSelectedAdvertiser();
    const history = useHistory();
    const [isUploading, setIsUploading] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const initialPixels = [
        { format: "url_image", secure_url: null },
        { format: "url_image", secure_url: null },
        { format: "url_js", secure_url: null }
    ];
    const [pixels, setPixels] = useState(initialPixels);
    const options = creativeSize.map(size => ({
        label: size.label,
        value: `${size.width}x${size.height}`
    }));

    useHubSpotHook('/creatives/create/html5');

    useEffect(() => {
        setCanSubmit(false);
        if (files.length > 0 && activeCreative.click_url) {
            setCanSubmit(true);
        }
    }, [files, activeCreative])

    const handleAddButton = (file) => {
        setFiles([...files, file]);
    }

    const updatePixel = (e, index) => {
        const format = index <= 1 ? "url_image" : "url_js"; // Assuming 0 and 1 are image pixels, 2 is js pixel.
        const updatedPixels = [...pixels];
        updatedPixels[index] = { format, secure_url: e.target.value || null }; // Use null if the value is empty
        setPixels(updatedPixels);
    };

    const handleUpload = async () => {
        setIsUploading(true);
        const toastId = toast('Your creatives are being uploaded...', {
            progressClassName: 'toastProgressHidden'
        });

        async function handleUpload(files) {
            const newHtmlAssets = [...mediaAssets];
            for (const file of files) {
                const uploadedFile = await uploadCreativeFile(file, "html");
                if (uploadedFile === 'File Size over 10MB') {
                    toast.dismiss(toastId);
                    toast.error('The file size is too large!', {
                        progressClassName: 'toastProgressError'
                    });
                    Sentry.captureMessage("The file size is too large! Type: Html");
                    return null;
                } else if (!uploadedFile) {
                    toast.dismiss(toastId);
                    toast.error('There was a problem uploading your file!', {
                        progressClassName: 'toastProgressError'
                    });
                    Sentry.captureMessage("There was a problem uploading your file! Type: Html");
                    return null;
                }
                newHtmlAssets.push({
                    media_asset_id: uploadedFile.data
                })
            }
            setMediaAssets(newHtmlAssets);
            return newHtmlAssets;
        }
        const media_assets = await handleUpload(files);
        if (!media_assets) {
            setIsUploading(false);
            setFiles([]);
            return;
        }

        if (!files[0]) {
            setIsUploading(false);
            toast.dismiss(toastId);
            toast.error('Must include a file', {
                progressClassName: 'toastProgressError'
            });
            return;
        }

        // Construct the pixels array for encodedCreative
        const conditionalPixels = pixels.map(pixel => {
            // If secure_url is empty, don't include the pixel
            if (!pixel.secure_url) return null;
            return pixel;
        }).filter(Boolean);  // Filter out null values

        const encodedActiveCreative = {
            ...activeCreative,
            name: files[0].name,
            media_assets,
            advertiser_id: selectedAdvertiser.id,
            width: Number(width),
            height: Number(height),
            pixels: conditionalPixels
        }
        //console.log('Encoded Creative:', encodedActiveCreative);

        axiosClient.post(
            `/creative-html`,
            encodedActiveCreative
        ).then(createdHtmlCreative => {
            setIsUploading(false);
            toast.dismiss(toastId);
            toast.success('Your creatives have been uploaded!', {
                progressClassName: 'toastProgressSuccess'
            });
            trackEvent({
                category: 'Snippet',
                action: 'select_item',
                label: "app.adpersonam.io"
            });
            sendSlackMessage(`:white_check_mark: A new HTML5 ad has been uploaded id: ${createdHtmlCreative.data.id}`)
            history.push('/snippets');
        })
            .catch(err => {
                setIsUploading(false);
                const responseData = err.response.data.message;
                toast.dismiss(toastId);
                toast.error(responseData, {
                    progressClassName: 'toastProgressError'
                });
                Sentry.captureException(err);
            });

    }

    const updateFormText = (field, e) => {
        const value = e.target.value;
        switch (field) {
            case 'click_url':
                setActiveCreative({
                    ...activeCreative,
                    click_url: value
                });
                break;
            case 'creativeSize':
                const [width, height] = value.split('x').map(Number);
                setWidth(width);
                setHeight(height);
                setActiveCreative({
                    ...activeCreative,
                    width,
                    height
                });
                break;
            default:
                console.error("Unhandled field:", field);
                Sentry.captureException(new Error(`Unhandled field: ${field}`));
                break;
        }
    };

    return (
        <CreativesCreate creativeType="html5">
            <Grid container direction='column' className={classes.root}>
                <Typography variant='h2' className={classes.title}>Upload HTML5 creatives</Typography>
                <Grid container alignItems='flex-end'>
                    <Grid item md={5}>
                        <UploadButton
                            title=".zip"
                            subtitle="Max. file size 150KB"
                            handleAddButton={handleAddButton}
                            label="Add Banners"
                            style={{ marginTop: 20 }}
                            isDisabled={files.length > 0}
                            datacy="button-upload-html-zip"
                        />
                    </Grid>
                    <Grid item md={7}>
                        <FilenameCards
                            files={files}
                            handleNewFiles={setFiles}
                        />
                    </Grid>
                </Grid>
                <Grid >
                    <SelectStyled
                        staticLabel="Select Creative Size"
                        style={{ marginTop: 20, width: '60%' }}
                        options={options}
                        handleUpdate={(e) => updateFormText('creativeSize', e)}
                        field="creativeSize"
                        noNone
                        datacy="select-optimization-creative-type"
                    />
                </Grid>
                <TextFieldStyled
                    staticLabel="Enter the URL where users will be redirected when they click the creative"
                    placeholder="This value must begin with “http://” or “https://"
                    sublabel="The landing page must match the language, brand, and offer of the creative"
                    style={{ marginTop: 10 }}
                    handleUpdate={(e) => updateFormText('click_url', e)}
                    field="click_url"
                    disabled={isUploading}
                    datacy="input-banner-click-url"
                />
                <AccordionStyled
                    title="Add third-party pixels (optional)"
                    style={{ marginTop: 20 }}
                >
                    {/* First Pixel */}
                    <TextField
                        id="filled-textarea"
                        multiline
                        minRows={0}
                        variant="outlined"
                        placeholder={"an url-image pixel starting with https://"}
                        field="pixels"
                        style={{ width: '100%' }}
                        InputProps={{
                            classes: {
                                input: classes.resize,
                            },
                        }}
                        onChange={(e) => updatePixel(e, 0)}
                    />

                    {/* Second Pixel */}
                    <TextField
                        id="filled-textarea"
                        multiline
                        minRows={0}
                        variant="outlined"
                        placeholder={"an url-image pixel starting with https://"}
                        field="pixels"
                        style={{ width: '100%', marginTop: 10 }}
                        InputProps={{
                            classes: {
                                input: classes.resize,
                            },
                        }}
                        onChange={(e) => updatePixel(e, 1)}
                    />

                    {/* Third Pixel */}
                    <TextField
                        id="filled-textarea"
                        multiline
                        minRows={0}
                        variant="outlined"
                        placeholder={"an url_js pixel starting with https://"}
                        field="pixels"
                        style={{ width: '100%', marginTop: 10 }}
                        InputProps={{
                            classes: {
                                input: classes.resize,
                            },
                        }}
                        onChange={(e) => updatePixel(e, 2)}
                    />
                </AccordionStyled>

                {isUploading ?
                    <Typography variant='h6' className={classes.uploadingText}>........</Typography>
                    :
                    <Grid container className={classes.buttons}>
                        <BackButtonOutlined
                            label="Back"
                        />
                        <ButtonContained
                            label="Upload"
                            style={{ marginLeft: 10 }}
                            onClick={handleUpload}
                            disabled={!canSubmit}
                            datacy="button-submit-banner"
                        />
                    </Grid>
                }
            </Grid>

        </CreativesCreate>
    );
};

export default HtmlCreate;