import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '22px',
    textAlign: 'center',
    color: '#051923',
    active: {
      fontWeight: 'bold',
    },
    marginRight: 10
  },
  activeLink: {
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '22px',
    textAlign: 'center',
    color: '#051923',
    fontWeight: 'bold',
    marginRight: 10
  },
}));
