import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: 42,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  logoImg: {
    width: 210
  },
  title: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 60,
    lineHeight: '78.12px',
    marginTop: 16,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center'
    },
  },
  subtitle: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 20,
    lineHeight: '26.04px',
    marginTop: 42,
    fontWeight: 400,
  },
  mainCont: {
    width: '60%',
    background: '#fff',
    borderRadius: 8,
    paddingBottom: 42,
    marginTop: 69,
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    },
  },
  cardsCont: {
    padding: 20,
    paddingLeft: 120,
    paddingRight: 120,
    paddingBottom: 36,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },

  },
  planCardSelected: {
    padding: 20,
    paddingTop: 40,
    paddingBottom: 40,
    background: '#09A1F9',
    borderRadius: 26
  },
  planCard: {
    padding: 20,
    paddingTop: 40,
    paddingBottom: 40,
    borderRadius: 26
  },
  planType: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 20,
    lineHeight: '26.04px',
    marginTop: 16,
    fontWeight: 700,
  },
  planTypeSelected: {
    color: '#fff',
    fontFamily: 'Open Sans',
    fontSize: 20,
    lineHeight: '26.04px',
    marginTop: 16,
    fontWeight: 700,
  },
  planPrice: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 60,
    lineHeight: '78.12px',
    marginTop: 16,
    fontWeight: 700,
  },
  planPriceSelected: {
    color: '#fff',
    fontFamily: 'Open Sans',
    fontSize: 60,
    lineHeight: '78.12px',
    marginTop: 16,
    fontWeight: 700,
  },
  planPeriod: {
    color: '#B5AEBB',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '18.23px',
    marginTop: 16,
    fontWeight: 700,
  },
  planPeriodSelected: {
    color: '#fff',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '18.23px',
    marginTop: 16,
    fontWeight: 700,
  },
  chooseButton: {
    background: '#F9F2FF',
    color: '#4E2D92'
  },
  chooseButtonSelected: {
    background: '#FFF',
    color: '#09A1F9'
  },
  rowsCont: {
    paddingLeft: 80,
    paddingRight: 80,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  greyRow: {
    background: '#F9F2FF',
    padding: 36,
    borderRadius: 26,
  },
  whiteRow: {
    background: '#fff',
    padding: 36,
    borderRadius: 26,
  },
  rowText: {
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 15,
    lineHeight: '19.53px',
    fontWeight: 700,
  },
  iconCont: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    background: '#05CAB0',
    color: '#fff',
    borderRadius: 123,
    width: 18,
    height: 18,
    padding: 2,
  }
}));
