import React from 'react';
import { useStyles } from './styles';
import { Route, useHistory } from 'react-router-dom';
import TopBar from 'components/navigation/TopBar';
// import Zoom from '@material-ui/core/Fade';
// import PrivateRouteLoading from 'components/routing/PrivateRouteLoading';

// IMPORT MATERIAL-UI COMPONENTS
import { Grid } from '@material-ui/core';
import { useGetOwnUser } from 'utils/hooks/userHooks';
import { Auth } from 'aws-amplify';

const PrivateRoute = ({
  component: Component,
  displayName,
  fullscreen,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { userData } = useGetOwnUser();
  // const [open, setOpen] = useState(true);

  // useEffect(() => {
  //   if(!userData) {
  //     history.push('/login');
  //   }
  // }, [userData]);

  Auth.currentAuthenticatedUser().catch(() => {
    history.push('/login')
  });

  if (displayName) {
    document.title = 'ad:personam - ' + displayName;
  }

  const content = (
    <Route
      {...rest}
      render={(props) => <Component {...props}/>}
    />
  );

  const layout = fullscreen ? (
    <Grid className='fullscreenContainer'>{content}</Grid>
  ) : (
    <Grid container direction='column'>
      <TopBar />
      <main className={classes.content} user={userData}>{content}</main>
    </Grid>
  );

  return layout;
};

export default PrivateRoute;