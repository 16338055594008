import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { Grid, Typography, TextField } from '@material-ui/core';
import TextFieldStyled from 'components/elements/TextFieldStyled';
import AccordionStyled from 'components/elements/AccordionStyled';
import ButtonContained from 'components/elements/ButtonContained';
import creativeDefault from 'utils/models/bannerCreative';
import { useGetSelectedAdvertiser } from 'utils/hooks/advertiserHooks';
import CreativesCreate from 'containers/CreativesCreate';
import BackButtonOutlined from 'components/elements/BackButtonOutlined';
import UploadButton from 'components/elements/UploadButton';
import FilenameCards from 'components/elements/FilenameCards';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import toBase64 from 'utils/functions/toBase64';
import getImageDimensions from 'utils/functions/getImageDimensions';
import { trackEvent } from 'utils/hooks/analyticsG4';
import sendSlackMessage from 'utils/functions/sendSlackMessage';
import { checkFileSize } from 'utils/functions/checkFileSize';
import { useHubSpotHook } from 'utils/hooks/hubSpotHooks';
import * as Sentry from "@sentry/react";

const BannerCreate = () => {
  const classes = useStyles();
  const [activeCreative, setActiveCreative] = useState(creativeDefault);
  const [files, setFiles] = useState([]);
  const { selectedAdvertiser } = useGetSelectedAdvertiser();
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const initialPixels = [
    { format: "url_image", secure_url: null },
    { format: "url_image", secure_url: null },
    { format: "url_js", secure_url: null }
  ];
  const [pixels, setPixels] = useState(initialPixels);

  useHubSpotHook('/creatives/create/banner');

  useEffect(() => {
    setCanSubmit(false);
    if (files.length > 0 && activeCreative.click_url) {
      setCanSubmit(true);
    }
  }, [files, activeCreative])

  const handleAddButton = (file) => {
    setFiles([...files, file]);
  }

  const updatePixel = (e, index) => {
    const format = index <= 1 ? "url_image" : "url_js"; // Assuming 0 and 1 are image pixels, 2 is js pixel.
    const updatedPixels = [...pixels];
    updatedPixels[index] = { format, secure_url: e.target.value || null }; // Use null if the value is empty
    setPixels(updatedPixels);
    //console.log(updatedPixels); // Log the updated state
  };

  const handleUpload = () => {
    setIsUploading(true);
    const toastId = toast('Your creatives are being uploaded...', {
      progressClassName: 'toastProgressHidden'
    });

    // Check file size before proceeding
    for (const file of files) {
      if (!checkFileSize(file, 150)) {
        toast.dismiss(toastId);
        toast.error('File size should be 150KB or less.', {
          progressClassName: 'toastProgressError'
        });
        setIsUploading(false);
        return;
      }
    }
    async function createCreative(file) {

      const content = await toBase64(file);
      const dimensions = await getImageDimensions(file);

      // Construct the pixels array for encodedCreative
      const conditionalPixels = pixels.map(pixel => {
        // If secure_url is empty, don't include the pixel
        if (!pixel.secure_url) return null;
        return pixel;
      }).filter(Boolean);  // Filter out null values

      //console.log(conditionalPixels);

      const encodedCreative = {
        ...activeCreative,
        advertiser_id: selectedAdvertiser.id,
        content,
        file_name: file.name,
        name: file.name,
        width: dimensions.width,
        height: dimensions.height,
        pixels: conditionalPixels,
        adchoices: {
          adchoices_enabled: true
        }
      };

      //console.log('Encoded Creative:', encodedCreative);

      const createdCreative = await axiosClient.post(
        `/creative`,
        encodedCreative
      )
        .catch(err => {
          console.log(err.response);
          Sentry.captureException(err);
          setIsUploading(false);
          return { error: file.name };
        });
      return createdCreative;
    }

    async function runFiles(files) {
      for (const file of files) {
        const createdCreative = await createCreative(file);
        if (createdCreative.error) {
          toast.dismiss(toastId);
          toast.error('There was a problem uploading ' + createdCreative.error, {
            progressClassName: 'toastProgressError'
          });
          Sentry.captureMessage(createdCreative.error);
          return;
        }
        sendSlackMessage(`:white_check_mark: A new banner has been uploaded id: ${createdCreative.data.id}`)
      }
      setIsUploading(false);
      toast.dismiss(toastId);
      toast.success('Your creatives have been uploaded!', {
        progressClassName: 'toastProgressSuccess'
      });
      trackEvent({
        category: 'Snippet',
        action: 'select_item',
        label: "app.adpersonam.io"
      });
      history.push('/snippets');
    }
    runFiles(files);

  }

  const updateFormText = (e) => {
    setActiveCreative({
      ...activeCreative,
      [e.target.id]: e.target.value
    })
  }

  return (
    <CreativesCreate creativeType="banner">
      <Grid container direction='column' className={classes.root}>
        <Typography variant='h2' className={classes.title}>Upload banner creatives</Typography>
        <Grid container alignItems='flex-end'>
          <Grid item md={5}>
            <UploadButton
              title="JPEG, PNG, GIF"
              subtitle="Max. file size 150 KB"
              handleAddButton={handleAddButton}
              label="Add Banners"
              style={{ marginTop: 20 }}
              datacy="button-upload-banner-file"
            />
          </Grid>
          <Grid item md={7}>
            <FilenameCards
              files={files}
              handleNewFiles={setFiles}
            />
          </Grid>
        </Grid>
        <TextFieldStyled
          staticLabel="Enter the URL where users will be redirected when they click the creative"
          placeholder="This value must begin with “http://” or “https://"
          sublabel="The landing page must match the language, brand, and offer of the creative"
          style={{ marginTop: 20 }}
          handleUpdate={updateFormText}
          field="click_url"
          disabled={isUploading}
          datacy="input-banner-click-url"
        />
        <AccordionStyled
          title="Add third-party pixels (optional)"
          style={{ marginTop: 20 }}
        >
          {/* First Pixel */}
          <TextField
            id="filled-textarea"
            multiline
            minRows={0}
            variant="outlined"
            placeholder={"an url-image pixel starting with https://"}
            field="pixels"
            style={{ width: '100%' }}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            onChange={(e) => updatePixel(e, 0)}
          />

          {/* Second Pixel */}
          <TextField
            id="filled-textarea"
            multiline
            minRows={0}
            variant="outlined"
            placeholder={"an url-image pixel starting with https://"}
            field="pixels"
            style={{ width: '100%', marginTop: 10 }}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            onChange={(e) => updatePixel(e, 1)}
          />

          {/* Third Pixel */}
          <TextField
            id="filled-textarea"
            multiline
            minRows={0}
            variant="outlined"
            placeholder={"an url_js pixel starting with https://"}
            field="pixels"
            style={{ width: '100%', marginTop: 10 }}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            onChange={(e) => updatePixel(e, 2)}
          />
        </AccordionStyled>



        {isUploading ?
          <Typography variant='h6' className={classes.uploadingText}>Uploading...</Typography>
          :
          <Grid container className={classes.buttons}>
            <BackButtonOutlined
              label="Back"
            />
            <ButtonContained
              label="Upload"
              style={{ marginLeft: 10 }}
              onClick={handleUpload}
              disabled={!canSubmit}
              datacy="button-submit-banner"
            />
          </Grid>
        }
      </Grid>

    </CreativesCreate>
  );
};

export default BannerCreate;
