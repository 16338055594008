import * as Sentry from "@sentry/react";

export default function errorHandler(err, errorInfo) {
  const newErr = {
    err: err ? err.toString() : 'No err string',
    componentStack: errorInfo ? errorInfo.componentStack : 'No error info',
    source: 'client'
  }
  // Handle error
  console.log(newErr);
  Sentry.captureException(err);
}