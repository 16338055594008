import awsmobile from './aws-exports';

const ENV = process.env.REACT_APP_ENV;
let userPool;
if (!ENV || ENV === 'dev') {
  userPool = {
    aws_user_pools_id: 'us-east-2_D8kDC31jK',
    aws_user_pools_web_client_id: `2or6ptk0us00hrt1genaetv5h4`, //2or6ptk0us00hrt1genaetv5h4 23t8kstfos4ko5dt3jgkjp4r3s
    oauth: {
      domain: 'auth.adpersonam.io',
      redirectSignIn: 'https://dev1.d1xgmnu76glf9v.amplifyapp.com/', //localhost:3123/
      redirectSignOut: 'https://dev1.d1xgmnu76glf9v.amplifyapp.com/',

      responseType: 'token'
    }
  };
}

if (ENV === 'prod') {
  userPool = {
    aws_user_pools_id: 'us-east-2_laq6lL3vj',
    aws_user_pools_web_client_id: '2uscgvl53dt5rrvocg5v4ift2p',
    oauth: {
      domain: 'oauth.adpersonam.io',
      redirectSignIn: 'https://app.adpersonam.io/',
      redirectSignOut: 'https://app.adpersonam.io/',

      responseType: 'token'
    }
  };
}

const awsmobilewithauth = {
  ...awsmobile,
  ...userPool,
};

export default awsmobilewithauth;
