import React from 'react';
import { useStyles } from './styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ArrowDown from 'icons/ui/ArrowDown';

const StyledAccordion = withStyles({
  root: {
    borderTop: '1px solid #D0D0D0',
    borderBottom: '1px solid #D0D0D0',
    boxShadow: 'none',
    '&:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const StyledAccordionDetails = withStyles((theme) => ({
  root: {
    background: '#FAFAFA',
    padding: '20px 10px',
  },
}))(AccordionDetails);

const AccordionStyled = ({
  title,
  children,
  style,
}) => {
  const classes = useStyles();

  return (
    <StyledAccordion style={style} className={classes.root}>
      <AccordionSummary
        expandIcon={<ArrowDown className={classes.icon} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.summary}
      >
        <Typography variant='h3' className={classes.title}>{title}</Typography>
      </AccordionSummary>
      <StyledAccordionDetails className={classes.summary}>
        {children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default AccordionStyled;
