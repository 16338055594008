import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        background: '#fff',
        boxShadow: '0px 8px 25px 0px #0000001A',
        padding: 32,
        position: 'relative',
        minHeight: 333,
        marginTop: 42,
    },
    header: {
        color: '#051923'
    },
    title: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
    },
    subtitle: {
        color: '#000000',
        fontFamily: 'Open Sans',
        fontSize: 16,
        lineHeight: '21.79px',
        marginTop: 16,
    },
    buttons: {
        marginTop: 30,
    },
    urlFormat: {
        color: '#051923',
        fontFamily: 'Open Sans',
        fontSize: 14,
        lineHeight: '21.79px',
    },
    cont: {
        position: 'relative',
        background: '#FAFAFA',
        padding: '32px 32px 32px 50px',
        boxShadow: '0px 8px 25px 0px #0000001A',
        [theme.breakpoints.down("sm")]: {
            padding: '32px 31px 32px 31px',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    showAllCont: {
        cursor: 'pointer',
        marginTop: 10,
        color: theme.palette.highlight.a1,
        '&:hover': {
            color: theme.palette.accent.a1
        }
    },
    marginTopGrid: {
        marginTop: theme.spacing(2.5), // Example using theme spacing for 20px if the default spacing is 8px
    },
    loader: {
        width: '100px',
        height: '100px',
        marginLeft: 'auto',
        marginRight: 'auto',
        //paddingTop: '35px'
    },
    cancelText: {
        color: theme.palette.highlight.a1
    },
    cancelCont: {
        position: 'relative',
        top: 20,
        textAlign: 'right',
    },
    icon: {
        color: '#0582CA',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.accent.a1
        }
    },
    disabledIcon: {
        color: '#D0D0D0',
    },
    reportCont: {
        marginTop: 40,
        marginLeft: 30,
        padding: theme.spacing(2),
        textAlign: 'left',
        position: 'relative',
        paddingBottom: 30,
        paddingTop: 30,
        boxShadow: '0px 8px 25px 0px #0000001A',
        background: '#FAFAFA',
        width: 'auto',
        border: `1px solid ${theme.palette.primary.main}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left'
    },
    loader: {
        marginTop: 40,
        marginLeft: 30,
        padding: theme.spacing(2),
        position: 'relative',
        paddingBottom: 30,
        paddingTop: 30,
        boxShadow: '0px 8px 25px 0px #0000001A',
        background: '#FAFAFA',
        width: 'auto',
        //border: `1px solid ${theme.palette.primary.main}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    text: {
        marginTop: 10,
        fontFamily: 'Open Sans',
        fontSize: 10,
        lineHeight: '21.79px',
    },
    textShow: {
        marginTop: 12,
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: '21.79px',
    },
    arrow: {
        fontSize: 32
    },
    iconButton: {
        display: 'flex',
        justifyContent: 'flex-start',
        borderRadius: 15,
        backgroundColor: theme.palette.primary.main,
        color: '#FAFAFA',
        width: '80%',
        cursor: 'pointer',
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.grey.light}`,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
        '&.Mui-disabled': {
            border: `1px solid ${theme.palette.primary.main}`, // Specific border style when disabled
        }
    }
}));
