const subBarSnippetsRoutes = [
    '/snippets',
    '/campaigns',
    '/reports',
    '/advertiser-settings',
    '/creatives/create',
    '/creatives/create/banner',
    '/creatives/create/native',
    '/creatives/create/audio',
    '/creatives/create/video',
    '/creatives/create/html5',
    '/creatives/create/ad-tag',
    '/creatives/create/vast-tag',
    '/creatives/create/url-tag',
    '/creatives/create/macro-check',
    '/pixels/create',
    '/pixels-general/create',
    '/domains/create',
    '/remarketing/create',
];

export default subBarSnippetsRoutes;