import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    padding: 10,
    width: 250,
    height: 68,
    borderRadius: 4,
    border: '1px solid #0582CA',
  },
  icon: {
    fontSize: 50,
    color: "#0582CA",
    marginRight: 10,
  },
  title: {
    fontFamily: "Open Sans",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    marginTop: 5,
  },
  label: {
    marginBottom: 10,
    fontFamily: "Open Sans",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
  }
}));
