const creativeHtml = {
    type: "banner",
    template: {
        id: 8606             //default
    },
    segments: null,
    allow_audit: "false",  //default
    is_self_audited: "false", //default
    sla: 0,
    adchoices: {
        adchoices_enabled: true
    }
}

export default creativeHtml;