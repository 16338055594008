import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  margin: {
    // marginTop: 0,
  },
  staticLabel: {
    fontSize: 12,
    fontFamily: 'Open Sans',
    lineHeight: "16.34px",
    fontWeight: 700,
    marginBottom: 0,
    marginLeft: 1,
    color: '#051923'
  },
  subLabel: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#A7A7A7',
  },
  input: {
    '& .MuiOutlinedInput-input': {
      paddingTop: 10.5,
      paddingBottom: 10.5,
    },
    background: '#fff',
  },
}));
